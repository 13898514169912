import React from "react";
import {
    Modal,
    Button,
} from "reactstrap";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { mapStyles, mapApiKey } from "variables/mapOptions.js";

function MapModal({openFlag, catchViewing, google, onClose}) {
    const [showModal, setShowModal] = React.useState(false);
    // const [currentLocation, setCurrentLocation] = React.useState({lat:-1,lng:-1});
    const [mapCenter, setMapCenter] = React.useState({lat: 41.619775,lng: -86.151929});
    React.useEffect(() => {
        setMapCenter({
            lat: catchViewing.latitude,
            lng: catchViewing.longitude
        });
        if (openFlag) {
            setShowModal(true);
            // getLocation();
        }
    }, [openFlag, catchViewing]);
    // const getLocation = () => {
    //     navigator?.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
    //         setCurrentLocation({lat: lat, lng: lng});
    //     });
    // };
    const _mapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyles,
        });
    };

    const toggle = () => {
        console.log('TGLER');
        setShowModal(!showModal);
        onClose();
    };
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                size="lg"
                backdrop={true}
                toggle={() => toggle()}
            >
                <div className="modal-header">
                    <h5 className="modal-title">
                        {catchViewing.formattedTimestamp}{" "}
                        {catchViewing.species}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" style={{ height: "400px" }}>
                    <Map
                        google={google}
                        zoom={14}
                        center={mapCenter}
                        initialCenter={mapCenter}
                        mapTypeControl={false}
                        streetViewControl={false}
                        containerStyle={{
                            height: "100%",
                            position: "relative",
                            width: "100%",
                        }}
                        onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
                    >
                            <Marker
								position={{lat: catchViewing.latitude, lng: catchViewing.longitude}}
                            />
                    </Map>
                </div>
                <div className="modal-footer pt-0">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggle()}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        );
    }

export default GoogleApiWrapper({
    apiKey: mapApiKey,
})(MapModal);