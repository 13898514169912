import React from "react";
import {
	Modal,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Button,
	Form,
	Row,
	Col,
	Spinner,
	Progress,
	Alert,
} from "reactstrap";
// import classnames from "classnames";
import { AdminContext } from "layouts/Admin";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faToolbox,
	faWeight,
	faPalette,
} from "@fortawesome/free-solid-svg-icons";
import Dropzone from "dropzone-exif-fix";
import { API, APIBaseUrl } from "../../services/API";
Dropzone.autoDiscover = false;

export default function AddCustomLure({openFlag, onClose}) {
	const [showModal, setShowModal] = React.useState(false);
	const [showLoading, setShowLoading] = React.useState(false);
	// const [lureNameFocus, setLureNameFocus] = React.useState(true);
	const [showImageUploadingSpinner, setShowImageUploadingSpinner] = React.useState(false);
	const [fileUploadProgress, setFileUploadProgress] = React.useState(0);
	const [showUploadDropzone, setShowUploadDropzone] = React.useState(true);
	const [imageUploaded, setImageUploaded] = React.useState(false);
	const [errorText, setErrorText] = React.useState('');
	const [disableSave, setDisableSave] = React.useState(true);
	const [customLureName, setCustomLureName] = React.useState('');
	const [customLureColor, setCustomLureColor] = React.useState('');
	const [customLureImage, setCustomLureImage] = React.useState('');
	const [customLureSize, setCustomLureSize] = React.useState('');
  React.useEffect(() => {
	if (openFlag) {
		setShowModal(true);
	}
  },[openFlag]);
	const { showNotification, performPageReload } = React.useContext(AdminContext);
  	const toggle = () => {
		console.log('TGLER');
		setShowUploadDropzone(true);
		setImageUploaded(false);
		setShowImageUploadingSpinner(false);
		setErrorText('');
		setCustomLureColor('');
		setCustomLureImage('');
		setCustomLureName('');
		setCustomLureSize('');
		setShowModal(!showModal);
		onClose();
	  };
	const modalOpened = () => {
		console.log("mntd");
		new Dropzone(document.getElementById("addNewLureUploadDropzone"), {
			url: APIBaseUrl + "/fish/imageUpload.php",
			dictDefaultMessage:
				"Drop an image or click here to upload a photo of the lure",
			maxFiles: 1,
			acceptedFiles: "image/*",
			resizeHeight: 128,
			resizeQuality: 1.0,
			previewTemplate: document.querySelector("#blankDzLurePhotoUploadTemplate")
				.innerHTML,
			init: function () {
				this.on("addedfile", function (file) {
					if (file.type.indexOf("image") < 0) {
						setErrorText("That is not an image file.");
						this.removeFile(file);
					} else {
						setShowImageUploadingSpinner(true);
						setShowUploadDropzone(false);
					}
				});
				this.on("sending", function (file, xhr, formData) {
					formData.append("getLocation", false);
				});
				this.on("uploadprogress", function (file, progress, bytesSent) {
					setFileUploadProgress(progress);
				});
				this.on("success", function (file, responseData) {
					responseData = JSON.parse(responseData);
					console.log(responseData);
					setShowUploadDropzone(false);
					setImageUploaded(true);
					setShowImageUploadingSpinner(false);
					setErrorText('');
					setCustomLureImage(responseData.filePath);
				});
				this.on("error", function (file, errorMessage) {
					console.error(errorMessage);
					setShowImageUploadingSpinner(false);
					setErrorText("Photo Upload error. Please try refreshing the page.");
				});
			},
		});
	};

	const saveCustomLure = () => {
		const subData = new FormData();
		subData.append("name", customLureName);
		subData.append("color", customLureColor);
		subData.append("image", customLureImage);
		subData.append("size", customLureSize);

		API.post("lureFiles/addLure.php", subData)
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				if (responseJson.status) {
					if (!responseJson.lureExists) {
						// this.props.reloadPageData();
						toggle();
						showNotification(
							"success",
							"Saved",
							"Lure added to your tackle box!"
						);
						performPageReload();
					}
				} else {
					setShowLoading(false);
					setErrorText("Error on our end. Try refreshing!");
				}
			})
			.catch((error) => {
				console.log(error);
				setShowLoading(false);
				setErrorText("Error on our end. Try refreshing!");
			});
	};

		return (
			<>
				<Modal
					className="modal-dialog-centered"
					isOpen={showModal}
					onOpened={modalOpened}
					size="lg"
					backdrop={true}
					toggle={() => toggle()}
				>
					<div className="modal-header">
						<h5 className="modal-title">New Lure</h5>
						<button
							aria-label="Close"
							className="close"
							data-dismiss="modal"
							type="button"
							onClick={() => toggle()}
						>
							<span aria-hidden={true}>×</span>
						</button>
					</div>
					<div className="modal-body">
						{showUploadDropzone && (
							<Row>
								<Col>
									<div
										className="dropzone dropzone-single mb-3"
										id="addNewLureUploadDropzone"
									>
										<div className="fallback">
											<div className="custom-file">
												<input
													className="custom-file-input"
													id="projectCoverUploads"
													type="file"
												/>
												<label
													className="custom-file-label"
													htmlFor="projectCoverUploads"
												>
													Choose file
												</label>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						)}
						{showImageUploadingSpinner && (
							<>
								<Row>
									<Col>
										<div className="text-center mb-5">
											<Spinner color="primary" />
										</div>
									</Col>
								</Row>
								<Row className="mb-4">
									<Col>
										<Progress
											className="progress-xs mb-0"
											color="success"
											max="100"
											value={fileUploadProgress}
										/>
									</Col>
								</Row>
							</>
						)}
						{imageUploaded && (
							<Row>
								<Col>
									<Alert color="success">
										<span className="alert-icon">
											<i className="ni ni-like-2" />
										</span>
										<span className="alert-text ml-1">
											<strong>Success!</strong> Lure image uploaded
										</span>
									</Alert>
								</Col>
							</Row>
						)}

						<div className="text-muted font-italic mb-4">
							<small className="text-danger font-weight-700">
								{errorText}
							</small>
						</div>
						<Form>
							<FormGroup className="row">
								<Col>
									<InputGroup
										// className={classnames("input-group-merge", {
										// 	focused: lureNameFocus,
										// })}
										className="input-group-merge"
									>
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<FontAwesomeIcon icon={faToolbox} />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder="Lure Name (required)"
											type="text"
											id="customLureName"
											// onFocus={(e) => setLureNameFocus(true)}
											// onBlur={(e) => setLureNameFocus(true)}
											onChange={(e) => {
												setCustomLureName(e.target.value);
												if (e.target.value.length > 1 && !showImageUploadingSpinner) {
													setDisableSave(false);
												} else {
													setDisableSave(true);
												}
											}}
										/>
									</InputGroup>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Col>
									<InputGroup
										className="input-group-merge"
									>
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<FontAwesomeIcon icon={faPalette} />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder="Lure Color"
											type="text"
											id="customLureColor"
											onChange={(e) => {
												setCustomLureColor(e.target.value);
											}}
										/>
									</InputGroup>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Col>
									<InputGroup
										className="input-group-merge"
									>
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<FontAwesomeIcon icon={faWeight} />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder="Lure Size (in, oz, does not matter)"
											type="text"
											id="customLureSize"
											onChange={(e) => {
												setCustomLureSize(e.target.value);
											}}
										/>
									</InputGroup>
								</Col>
							</FormGroup>
						</Form>
					</div>
					<div className="modal-footer pt-0">
						<Button
							className="ml-auto"
							color="success"
							type="button"
							disabled={disableSave}
							onClick={saveCustomLure}
						>
							Save to Tackle Box
						</Button>
					</div>
				</Modal>
				<div id="blankDzLurePhotoUploadTemplate">
					<div></div>
				</div>
			</>
		);
}
