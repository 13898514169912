import React from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { API } from "services/API";
import { AdminContext } from "layouts/Admin";
import OfferedPlansTable from "components/Plans/OfferedPlansTable";

export default function Profile() {
  const [showLoading, setShowLoading] = React.useState(true);
  const [myProfile, setMyProfile] = React.useState(false);
  const [reloadTrigger, setReloadTrigger] = React.useState(1);
  const [profile, setProfile] = React.useState({});
  const [profileImage, setProfileImage] = React.useState(false);
  const [isProAngler, setIsProAngler] = React.useState(false);
  const [linkToProfile, setLinkToProfile] = React.useState("");
  const { showNotification } = React.useContext(AdminContext);
  const { userId } = useParams();
  const [searchParams] = useSearchParams();
  const redir = useNavigate();

  const checkTransit = async () => {
    if (searchParams.has("transit")) {
      let transit = searchParams.get("transit");
      API.get("mobile/transition.php?transitHash=" + transit)
        .then((responseJson) => {
          responseJson = responseJson.data;
          console.log(responseJson);
          if (responseJson.status) {
            localStorage.setItem("fishuem", responseJson.encEmail);
            localStorage.setItem("fishuid", responseJson.uuid);
            localStorage.setItem("fishuimg", responseJson.uimg);
            localStorage.setItem("fishuname", responseJson.name);
            API.interceptors.request.use(function (config) {
              config.headers = {
                "Content-Type": "multipart/form-data",
                "X-EncId": localStorage.getItem("fishuid"),
                "X-EncEm": localStorage.getItem("fishuem"),
              };
              return config;
            });
            redir("/admin/profile/" + userId);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const loadData = async () => {
    console.log(localStorage.getItem("fishuid"));
    let uid = userId;
    if (userId === undefined || userId == localStorage.getItem("fishuid")) {
      uid = -1;
      setMyProfile(true);
      setLinkToProfile(
        "https://bitelogr.app/admin/profile/" + localStorage.getItem("fishuid")
      );
    } else {
      setLinkToProfile("https://bitelogr.app/admin/profile/" + userId);
      setMyProfile(false);
    }
    console.log("LOADPROFILE");
    API.get("userFiles/getProfile.php?uid=" + uid)
      .then((responseJson) => {
        responseJson = responseJson.data;
        responseJson.profileImage = responseJson.profileImage
          ? responseJson.profileImage
          : require("assets/img/brand/biteLogrLogos/3dLogoOnly.jpg");
        setProfile(responseJson);
        setIsProAngler(responseJson.isProAngler);
        document.title = responseJson.name;
        console.log(responseJson);
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
  };
  React.useEffect(() => {
    checkTransit();
    loadData();
  }, [userId]);

  const saveEdits = () => {
    setShowLoading(true);
    const formData = new FormData();
    formData.append("editing", 1);
    formData.append("name", profile.name);
    formData.append("homeLocation", profile.homeLocation);
    formData.append("homeWater", profile.homeWater);
    formData.append("profileSummary", profile.profileSummary);
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    API.post("userFiles/users.php", formData, config)
      .then((responseJson) => {
        responseJson = responseJson.data;
        console.log(responseJson);
        if (responseJson.profileImagePath != "") {
          setProfile((current) => ({
            ...current,
            profileImage: responseJson.profileImagePath,
          }));
          localStorage.setItem("fishuimg", responseJson.profileImagePath);
        }
        localStorage.setItem("fishuname", profile.name);
        setShowLoading(false);
        showNotification("success", "Saved", "Profile saved!");
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
        showNotification(
          "error",
          "Issue",
          "Unable to save... please refresh and try again"
        );
      });
  };

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "410px",
          backgroundImage:
            'url("' + require("assets/img/theme/profile-cover2.jpg") + '")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            {myProfile && (
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">Hello {profile.name}</h1>
                <p className="text-white mt-0 mb-5">
                  This is your public profile page. Here you can edit what other
                  anglers will see when they click on your page if you are
                  offering paid plans.
                </p>
                {!showLoading && isProAngler && (
                  <p className="text-white mt-0 mb-5">
                    The best way to grow your following and your bank account is
                    to share your profile on your social media. Click the button
                    below to share your unique BiteLogr profile link.
                    <br />
                    <span className="badge badge-success badge-pill">
                      {linkToProfile}
                    </span>
                  </p>
                )}
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("editPane")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Edit profile
                </Button>
                {!showLoading && isProAngler && (
                  <Button
                    className="btn-neutral"
                    color="default"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(linkToProfile);
                      showNotification(
                        "success",
                        "Copied",
                        "Link copied to clipboard"
                      );
                    }}
                  >
                    Copy link to share profile
                  </Button>
                )}
              </Col>
            )}
            {!myProfile && (
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">{profile.name}</h1>
                {/* <p className="text-white mt-0 mb-5">
                  This is your public profile page. Here you can edit what other
                  anglers will see when they click on your page if you are
                  offering paid plans.
                </p>
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEdit(true);
                    document
                      .getElementById("editPane")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Edit profile
                </Button> */}
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row className="justify-content-center">
          {/* <Col className="order-xl-2" xl="4"> */}
          <Card className="card-profile">
            {/* <CardImg
              alt="..."
              src={require("assets/img/theme/img-1-1000x600.jpg")}
              top
            /> */}
            <Row className="justify-content-center">
              <Col className="order-lg-2" lg="3">
                <div className="card-profile-image mt--3">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={profile.profileImage}
                    />
                  </a>
                </div>
              </Col>
            </Row>
            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div className="d-flex justify-content-between">
                {/* <Button
                  className="mr-4"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Social Media
                </Button>
                <Button
                  className="float-right"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Message
                </Button> */}
              </div>
            </CardHeader>
            <CardBody className="pt-0">
              <Row>
                <div className="col">
                  <div className="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span className="heading">{profile.totalCatches}</span>
                      <span className="description">Fish Logged</span>
                    </div>
                    <div>
                      <span className="heading">{profile.thisMonth}</span>
                      <span className="description">This month</span>
                    </div>
                    <div>
                      <span className="heading">{profile.subs}</span>
                      <span className="description">Subscribers</span>
                    </div>
                  </div>
                </div>
              </Row>
              <div className="text-center">
                <h5 className="h3">{profile.name}</h5>
                <div className="h5 font-weight-300">
                  <i className="ni location_pin mr-2" />
                  {profile.homeLocation}
                </div>
                <div className="h5 font-weight-300 mt-2">
                  <i className="ni location_pin mr-2" />
                  {profile.homeWater}
                </div>
                <div className="h5 mt-4">
                  <i className="ni business_briefcase-24 mr-2" />
                  Last catch: {profile.lastCatch}
                </div>
                <div>
                  <i className="ni education_hat mr-2" />
                  Top species:
                  <br />
                  {profile.topSpecies}
                </div>
              </div>
              <div className="text-center mt-2 text-muted">
                {profile.profileSummary}
              </div>
            </CardBody>
          </Card>
          {/* </Col> */}
        </Row>
        {!myProfile && !showLoading && (
          <>
            <Row className="justify-content-center">
              <h1>Paid Access Plans Offered</h1>
            </Row>
            <Row className="row-grid justify-content-center">
              <Col lg="10">
                <OfferedPlansTable
                  reloadPageData="0"
                  userId={userId}
                  publicVersion={false}
                />
              </Col>
            </Row>
          </>
        )}
        {myProfile && (
          <Row className="justify-content-center" id="editPane">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit profile</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        saveEdits();
                      }}
                      size="sm"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="edit-name"
                          >
                            Name
                          </label>
                          <Input
                            defaultValue={profile.name}
                            id="edit-name"
                            placeholder="Name"
                            type="text"
                            onChange={(e) => {
                              setProfile((current) => ({
                                ...current,
                                name: e.target.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="edit-location"
                          >
                            City / State
                          </label>
                          <Input
                            defaultValue={profile.homeLocation}
                            id="edit-location"
                            placeholder="Richmond, VA"
                            type="text"
                            onChange={(e) => {
                              setProfile((current) => ({
                                ...current,
                                homeLocation: e.target.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="edit-water"
                          >
                            Home Water
                          </label>
                          <Input
                            defaultValue={profile.homeWater}
                            id="edit-water"
                            placeholder="James River"
                            type="text"
                            onChange={(e) => {
                              setProfile((current) => ({
                                ...current,
                                homeWater: e.target.value,
                              }));
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">
                    Profile Image
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="edit-profile-image"
                            lang="en"
                            type="file"
                            onChange={(e) => {
                              setProfileImage(e.target.files[0]);
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="edit-profile-image"
                          >
                            Select file
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">About me</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label className="form-control-label">About Me</label>
                      <Input
                        placeholder="A few words about you ..."
                        rows="4"
                        type="textarea"
                        defaultValue={profile.profileSummary}
                        onChange={(e) => {
                          setProfile((current) => ({
                            ...current,
                            profileSummary: e.target.value,
                          }));
                        }}
                      />
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Row>
        )}
      </Container>
    </>
  );
}
