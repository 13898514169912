import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/API";
import LureIcon from "components/Icons/LureIcon";
import FishIcon from "components/Icons/FishIcon";
import RodAndFishIcon from "components/Icons/RodAndFishIcon";

function CardsHeader({ name, parentName, reloadTrigger }) {
  const redir = useNavigate();
  const getHeaderInfo = () => {
    console.log(localStorage.getItem("fishuid"));
    console.log(localStorage.getItem("fishuem"));
    if (localStorage.getItem("fishuid") === null) {
      redir("/auth/login");
    }
    API.get("getHeaderInfo.php")
      .then((responseJson) => {
        console.log(responseJson);
        if (!responseJson.data.noCatches) {
          setHeadBoxInfo(responseJson.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [showLoading, setShowLoading] = React.useState(false);
  const [headBoxInfo, setHeadBoxInfo] = React.useState({
    lastCatchData: {
      size: "",
      datetime: "None",
      species: "Add one!",
    },
    currentMonthSuccess: {
      catches: "",
      differenceFromLastMonth: 0,
    },
    bestLureData: {
      lure: "",
      catches: 0,
    },
    totalCatches: {
      overall: 0,
      topSpecies: "",
      topSpeciesCount: "",
    },
    noCatches: false,
  });
  React.useEffect(() => {
    if (reloadTrigger > 0) getHeaderInfo();
  }, [reloadTrigger]);
  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 d-inline-block mb-0 text-white">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="/admin/homecatchlist">
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="/admin/homecatchlist">{parentName}</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3" className="d-flex">
                <Card className="flex-fill card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Last catch
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {headBoxInfo.lastCatchData.datetime}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <FishIcon color="white" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="h4 text-danger text-nowrap">
                        {headBoxInfo.lastCatchData.size}{" "}
                        {headBoxInfo.lastCatchData.species}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3" className="d-flex">
                <Card className="flex-fill card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Catches this month
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {headBoxInfo.currentMonthSuccess.catches}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-calendar-grid-58" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      {headBoxInfo.currentMonthSuccess.differenceFromLastMonth >
                        0 && (
                        <span className="h4 text-warning mr-2">
                          <i className="fa fa-arrow-up mr-1" />
                          {"+"}
                          {
                            headBoxInfo.currentMonthSuccess
                              .differenceFromLastMonth
                          }
                        </span>
                      )}
                      {headBoxInfo.currentMonthSuccess.differenceFromLastMonth <
                        0 && (
                        <span className="h4 text-warning mr-2">
                          <i className="fa fa-arrow-down" />
                          {
                            headBoxInfo.currentMonthSuccess
                              .differenceFromLastMonth
                          }
                        </span>
                      )}
                      {headBoxInfo.currentMonthSuccess
                        .differenceFromLastMonth === 0 && (
                        <span className="h4 text-warning mr-2">{"+0"}</span>
                      )}
                      <span className="h4 text-warning text-nowrap">
                        vs last month
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3" className="d-flex">
                <Card className="flex-fill card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Best Lure
                        </CardTitle>
                        <span className="h4 font-weight-bold mb-0">
                          {headBoxInfo.bestLureData.lure}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <LureIcon color="white" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="h4 text-success mr-2">
                        <i className="fa fa-arrow-up" />{" "}
                        {headBoxInfo.bestLureData.catches}
                      </span>{" "}
                      <span className="h4 text-success text-nowrap">
                        Total catches
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3" className="d-flex">
                <Card className="flex-fill card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Fish caught
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {headBoxInfo.totalCatches.overall}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                          <RodAndFishIcon color="white" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="h4 text-primary mr-2">
                        {headBoxInfo.totalCatches.topSpeciesCount}
                      </span>{" "}
                      <span className="h4 text-primary text-nowrap">
                        {headBoxInfo.totalCatches.topSpecies}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
