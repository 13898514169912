import React from "react";
import {
  useParams,
  useSearchParams,
  useNavigate,
  Link,
} from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import { API } from "services/API";
import OfferedPlansTable from "components/Plans/OfferedPlansTable";
import AuthHeader from "components/Headers/AuthHeader.js";

export default function PublicProfile() {
  const [showLoading, setShowLoading] = React.useState(true);
  const [myProfile, setMyProfile] = React.useState(false);
  const [reloadTrigger, setReloadTrigger] = React.useState(1);
  const [profile, setProfile] = React.useState({});
  const [profileImage, setProfileImage] = React.useState(false);
  const [isProAngler, setIsProAngler] = React.useState(false);
  const [linkToProfile, setLinkToProfile] = React.useState("");
  const { userId } = useParams();
  const [searchParams] = useSearchParams();
  const redir = useNavigate();
  const loadData = async () => {
    console.log(localStorage.getItem("fishuid"));
    let uid = userId;
    console.log("LOADPROFILE");
    API.get("userFiles/getProfile.php?uid=" + uid)
      .then((responseJson) => {
        responseJson = responseJson.data;
        responseJson.profileImage = responseJson.profileImage
          ? responseJson.profileImage
          : require("assets/img/brand/biteLogrLogos/3dLogoOnly.jpg");
        setProfile(responseJson);
        setIsProAngler(responseJson.isProAngler);
        document.title = responseJson.name;
        console.log(responseJson);
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
  };
  React.useEffect(() => {
    if (
      localStorage.getItem("fishuid") !== null &&
      localStorage.getItem("fishuem") !== null
    ) {
      redir("/admin/profile/" + userId);
    }
    loadData();
  }, [userId]);

  const saveEdits = () => {
    setShowLoading(true);
    const formData = new FormData();
    formData.append("editing", 1);
    formData.append("name", profile.name);
    formData.append("homeLocation", profile.homeLocation);
    formData.append("homeWater", profile.homeWater);
    formData.append("profileSummary", profile.profileSummary);
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    API.post("userFiles/users.php", formData, config)
      .then((responseJson) => {
        responseJson = responseJson.data;
        console.log(responseJson);
        if (responseJson.profileImagePath != "") {
          setProfile((current) => ({
            ...current,
            profileImage: responseJson.profileImagePath,
          }));
        }
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
  };

  return (
    <>
      {/* <AuthHeader title="" lead="" /> */}

      <Container className="mt-1" fluid>
        <Row className="mb-7 mb-md-0">
          <Col className="collapse-brand" xs="6">
            <Link to="/auth/home">
              <img
                alt="..."
                src={require("assets/img/brand/biteLogrLogos/LogoHorizontal.png")}
                width="300"
              />
            </Link>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {/* <Col className="order-xl-2" xl="4"> */}
          <Card className="card-profile">
            {/* <CardImg
              alt="..."
              src={require("assets/img/theme/img-1-1000x600.jpg")}
              top
            /> */}
            <Row className="justify-content-center">
              <Col className="order-lg-2" lg="3">
                <div className="card-profile-image mt--3">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={profile.profileImage}
                    />
                  </a>
                </div>
              </Col>
            </Row>
            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              <div className="d-flex justify-content-between">
                {/* <Button
                  className="mr-4"
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Social Media
                </Button>
                <Button
                  className="float-right"
                  color="default"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Message
                </Button> */}
              </div>
            </CardHeader>
            <CardBody className="pt-0">
              <Row>
                <div className="col">
                  <div className="card-profile-stats d-flex justify-content-center">
                    <div>
                      <span className="heading">{profile.totalCatches}</span>
                      <span className="description">Fish Logged</span>
                    </div>
                    <div>
                      <span className="heading">{profile.thisMonth}</span>
                      <span className="description">This month</span>
                    </div>
                    <div>
                      <span className="heading">{profile.subs}</span>
                      <span className="description">Subscribers</span>
                    </div>
                  </div>
                </div>
              </Row>
              <div className="text-center">
                <h5 className="h3">{profile.name}</h5>
                <div className="h5 font-weight-300">
                  <i className="ni location_pin mr-2" />
                  {profile.homeLocation}
                </div>
                <div className="h5 font-weight-300 mt-2">
                  <i className="ni location_pin mr-2" />
                  {profile.homeWater}
                </div>
                <div className="h5 mt-4">
                  <i className="ni business_briefcase-24 mr-2" />
                  Last catch: {profile.lastCatch}
                </div>
                <div>
                  <i className="ni education_hat mr-2" />
                  Top species:
                  <br />
                  {profile.topSpecies}
                </div>
              </div>
              <div className="text-center mt-2 text-muted">
                {profile.profileSummary}
              </div>
            </CardBody>
          </Card>
        </Row>
        {!showLoading && (
          <>
            <Row className="justify-content-center bg-white ml-4 mr-4 pt-3">
              <h1>Paid Access Plans Offered</h1>
            </Row>
            <Row className="row-grid justify-content-center bg-white ml-4 mr-4">
              <Col lg="10">
                <OfferedPlansTable
                  reloadPageData="0"
                  userId={userId}
                  publicVersion={true}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}
