import React from "react";
import { Button, Card, CardHeader, CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faInfo } from "@fortawesome/free-solid-svg-icons";

export default function CatchCard({
  weight,
  length,
  onShowInfoClick,
  onShowMapClick,
  disableMapBtn,
  datetime,
  species,
  featuredImage,
  isFish,
  anglerName,
  anglerProfileImage,
}) {
  const [size, setSize] = React.useState("");
  anglerProfileImage = anglerProfileImage
    ? anglerProfileImage
    : require("assets/img/brand/biteLogrLogos/3dLogoOnly.jpg");
  const determineSizeString = () => {
    if (weight === "N/A" && length === "N/A") {
      return "";
    } else if (weight === "N/A") {
      return length;
    } else if (length === "N/A") {
      return weight;
    }
    return weight + " " + length;
  };
  React.useEffect(() => {
    setSize(determineSizeString());
  }, [determineSizeString]);
  const onActionClick = (e, actionName) => {
    e.preventDefault();
    if (actionName === "showInfo") {
      onShowInfoClick();
    } else if (actionName === "showMap") {
      onShowMapClick();
    }
  };
  return (
    <Col lg="4">
      <Card>
        <CardHeader
          style={{
            height: "380px",
          }}
        >
          {/* { isFish == 0 && (<h1 className="h1 text-center">Report</h1>) } */}
          <img
            loading="lazy"
            src={featuredImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "0.375em",
              opacity: isFish == 1 ? "1" : "0.5",
              filter: isFish == 1 ? "grayscale(0)" : "grayscale(1)",
            }}
          />
          <Row className="justify-content-center mt--4">
            <Col className="order-lg-2" lg="3">
              <div className="avatar">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img alt={anglerName} src={anglerProfileImage} />
                </a>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          <div className="mt-auto text-center">
            <h5 className="h3 title">
              <span className="d-block mb-1 text-muted">{anglerName}</span>
              <span className="d-block mb-1">{datetime}</span>
              <small className="h4 font-weight-light text-muted">
                {size}{" "}
                {species.length > 1 ? species : isFish == 1 ? "Fish" : "Report"}
              </small>
            </h5>
            <div className="mt-3">
              <Button
                className="btn btn-icon-only rounded-circle"
                color="slack"
                href="#map"
                disabled={disableMapBtn}
                onClick={(e) => onActionClick(e, "showMap")}
              >
                <FontAwesomeIcon icon={faMapMarkedAlt} />
              </Button>
              <Button
                className="btn-icon-only rounded-circle"
                color="default"
                href="#info"
                onClick={(e) => onActionClick(e, "showInfo")}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
              {/* <Button
									className="btn-icon-only rounded-circle"
									color="facebook"
									href="#fb"
									onClick={(e) => e.preventDefault()}
								>
									<i className="fab fa-facebook" />
								</Button> */}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
