import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { useNavigate } from "react-router-dom";
import AuthHeader from "components/Headers/AuthHeader.js";
import Cookies from "universal-cookie";
import { API } from "services/API";

export default function Home() {
  let cookies = new Cookies();
  const redir = useNavigate();
  React.useEffect(() => {
    if (
      cookies.get("fishuem") !== undefined &&
      cookies.get("fishuid") !== undefined &&
      cookies.get("fishuimg") !== undefined &&
      cookies.get("fishuname") !== undefined
    ) {
      localStorage.setItem("fishuem", cookies.get("fishuem"));
      localStorage.setItem("fishuid", cookies.get("fishuid"));
      localStorage.setItem("fishuimg", cookies.get("fishuimg"));
      localStorage.setItem("fishuname", cookies.get("fishuname"));
      // localStorage.setItem("fishuexp", moment().add(15, "m").format());
      API.interceptors.request.use(function (config) {
        config.headers = {
          "Content-Type": "multipart/form-data",
          "X-EncId": localStorage.getItem("fishuid"),
          "X-EncEm": localStorage.getItem("fishuem"),
        };
        return config;
      });
      redir("/admin/homecatchlist");
    }
    document.title = "Bite Logr Home";
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AuthHeader title="" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6">
            <div className="pricing card-group flex-column flex-md-row mb-3">
              <Card className="card-pricing border-0 text-center mb-4 zoom-in">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    <img
                      alt="..."
                      style={{ maxHeight: "200px" }}
                      src={require("assets/img/brand/biteLogrLogos/LOGO.png")}
                    />
                  </div>
                </CardHeader>
                <CardBody className="px-lg-4">
                  <div className="display-2">FREE</div>
                  <span className="text-muted">Pro Fishing App</span>
                  <ul className="list-unstyled my-4">
                    <li>
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                            <i className="fas fa-fish" />
                          </div>
                        </div>
                        <div>
                          <span className="pl-2">
                            Privately Catalog Catches
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                            <i className="fas fa-sun" />
                          </div>
                        </div>
                        <div>
                          <span className="pl-2">
                            Enrich data with weather, moon and water data
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                            <i className="fas fa-chart-line" />
                          </div>
                        </div>
                        <div>
                          <span className="pl-2">
                            Analyze trends and patterns in your fish
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a href="/auth/register">
                    <Button className="mb-3" color="info" type="button">
                      Register
                    </Button>
                  </a>
                </CardBody>
                <CardFooter>
                  <a
                    className="text-light"
                    href="./"
                    onClick={(e) => e.preventDefault()}
                  >
                    Up your fishing game
                  </a>
                </CardFooter>
              </Card>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-lg-center px-3 mt-5">
          <div>
            <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
              <i className="ni ni-building text-primary" />
            </div>
          </div>
          <Col lg="6">
            <p className="text-white">
              <strong>This will be stored PRIVATELY as YOUR data.</strong> All
              insights shown are derived from only the catches you log and no
              one else's. This will ensure your fishing experience is yours and
              only yours.
            </p>
          </Col>
        </div>
        <Row className="row-grid justify-content-center">
          <Col lg="10">
            <Table className="table-dark mt-5" responsive>
              <thead>
                <tr>
                  <th className="px-0 bg-transparent" scope="col">
                    <span className="text-light font-weight-700">Features</span>
                  </th>
                  <th className="text-center bg-transparent" scope="cols">
                    Bite Logr
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-0">Map of catches</td>
                  <td className="text-center">
                    <i className="fas fa-check text-success" />
                  </td>
                </tr>
                <tr>
                  <td className="px-0">Best lures/tactics for conditions</td>
                  <td className="text-center">
                    <i className="fas fa-check text-success" />
                  </td>
                </tr>
                <tr>
                  <td className="px-0">Realize condition patterns</td>
                  <td className="text-center">
                    <i className="fas fa-check text-success" />
                  </td>
                </tr>
                <tr>
                  <td className="px-0">Weather data attached to each catch</td>
                  <td className="text-center">
                    <i className="fas fa-check text-success" />
                  </td>
                </tr>
                <tr>
                  <td className="px-0">Moon data added to each catch</td>
                  <td className="text-center">
                    <i className="fas fa-check text-success" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}
