import React from "react";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Spinner,
  Container,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import AuthHeader from "components/Headers/AuthHeader.js";
import Cookies from "universal-cookie";
import { API } from "services/API";

export default function Login() {
  const [emailInput, setEmailInput] = React.useState("");
  const [passInput, setPassInput] = React.useState("");
  const [rememberMeInput, setRememberMeInput] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [showLoading, setShowLoading] = React.useState(false);
  const [forgotModalOpen, setForgotModalOpen] = React.useState(false);
  const [forgotPassInput, setForgotPassInput] = React.useState("");
  const [showNewPassSuccess, setShowNewPassSuccess] = React.useState(false);
  const [showLoginTimeout, setShowLoginTimeout] = React.useState(false);
  let cookies = new Cookies();
  const redir = useNavigate();
  React.useEffect(() => {
    if (
      cookies.get("fishuem") !== undefined &&
      cookies.get("fishuid") !== undefined &&
      cookies.get("fishuimg") !== undefined &&
      cookies.get("fishuname") !== undefined
    ) {
      completeLogin(
        cookies.get("fishuem"),
        cookies.get("fishuid"),
        cookies.get("fishuimg"),
        cookies.get("fishuname")
      );
    } else {
      localStorage.removeItem("fishuem");
      localStorage.removeItem("fishuid");
      localStorage.removeItem("fishuimg");
      localStorage.removeItem("fishuname");
      // localStorage.removeItem("fishuexp");
    }
    document.title = "Login";
    // if (this.props.match.params.alertVal) {
    // 	if (this.props.match.params.alertVal === "newPass") {
    // 		this.setState({
    // 			showNewPassSuccess: true,
    // 		});
    // 	} else if (this.props.match.params.alertVal === "expired") {
    // 		this.setState({
    // 			showLoginTimeout: true,
    // 		});
    // 	}
    // }
    // createCalendar();
    // eslint-disable-next-line
  }, []);
  const sendPasswordReset = () => {
    setForgotModalOpen(false);
  };
  const completeLogin = (uem, uid, uimg, uname) => {
    localStorage.setItem("fishuem", uem);
    localStorage.setItem("fishuid", uid);
    localStorage.setItem("fishuimg", uimg);
    localStorage.setItem("fishuname", uname);
    // localStorage.setItem("fishuexp", moment().add(15, "m").format());
    API.interceptors.request.use(function (config) {
      config.headers = {
        "Content-Type": "multipart/form-data",
        "X-EncId": localStorage.getItem("fishuid"),
        "X-EncEm": localStorage.getItem("fishuem"),
      };
      return config;
    });
    redir("/admin/homecatchlist");
  };

  const onLoginAttempt = () => {
    console.log("LGA");
    setShowLoading(true);
    const subData = new FormData();
    subData.append("email", emailInput);
    subData.append("password", passInput);
    API.post("userFiles/checkLogin.php", subData)
      .then((responseJson) => {
        responseJson = responseJson.data;
        console.log(responseJson);
        if (responseJson.status) {
          if (responseJson.matches === 0) {
            if (rememberMeInput) {
              cookies.set("fishuem", responseJson.encEmail, {
                path: "/",
                sameSite: "strict",
              });
              cookies.set("fishuid", responseJson.uuid, {
                path: "/",
                sameSite: "strict",
              });
              cookies.set("fishuimg", responseJson.uimg, {
                path: "/",
                sameSite: "strict",
              });
              cookies.set("fishuname", responseJson.name, {
                path: "/",
                sameSite: "strict",
              });
            } else {
              cookies.remove("fishuem", { path: "/" });
              cookies.remove("fishuid", { path: "/" });
              cookies.remove("fishuimg", { path: "/" });
              cookies.remove("fishuname", { path: "/" });
            }
            completeLogin(
              responseJson.encEmail,
              responseJson.uuid,
              responseJson.uimg,
              responseJson.name
            );
          } else if (responseJson.matches === 1) {
            setShowLoading(false);
            setErrorText("Email not found. Need to register?");
          } else {
            setShowLoading(false);
            setErrorText("Incorrect Password");
          }
        } else {
          setShowLoading(false);
          setErrorText("Error on our end. Try refreshing");
        }
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
        setErrorText("Error on our end. Try refreshing!");
      });
  };
  return (
    <>
      <AuthHeader title="" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <div className="text-center">
                  <img
                    alt="..."
                    style={{ maxHeight: "200px" }}
                    src={require("assets/img/brand/biteLogrLogos/LOGO.png")}
                  />
                </div>
                {/* <div className="text-muted text-center mt-4 mb-3">
										<small>Sign in with</small>
									</div>
									<div className="btn-wrapper text-center">
										<Button
											className="btn-neutral btn-icon mr-4"
											color="default"
											href="#pablo"
											onClick={(e) => e.preventDefault()}
										>
											<span className="btn-inner--icon">
												<img
													alt="Facebook Logo"
													src={require("assets/img/icons/common/facebook.svg")}
												/>
											</span>
											<span className="btn-inner--text">Facebook</span>
										</Button>
										<Button
											className="btn-neutral btn-icon"
											color="default"
											href="#pablo"
											onClick={(e) => e.preventDefault()}
										>
											<span className="btn-inner--icon">
												<img
													alt="..."
													src={require("assets/img/icons/common/google.svg")}
												/>
											</span>
											<span className="btn-inner--text">Google</span>
										</Button>
									</div> */}
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                {showNewPassSuccess && (
                  <Alert color="success">
                    <strong>Success!</strong> Login with your new password!
                  </Alert>
                )}
                {showLoginTimeout && (
                  <Alert color="warning">
                    You have been logged out due to inactivity
                  </Alert>
                )}
                {!showNewPassSuccess && (
                  <div className="text-center text-muted mb-4">
                    <small>Sign In</small>
                  </div>
                )}
                <Form role="form" onSubmit={onLoginAttempt}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        id="emailInput"
                        onChange={(e) => {
                          setEmailInput(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        id="passInput"
                        onChange={(e) => {
                          setPassInput(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="rememberMeInput"
                      type="checkbox"
                      onChange={(e) => {
                        setRememberMeInput(e.target.value);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="rememberMeInput"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-muted font-italic">
                    <small className="text-danger font-weight-700">
                      {errorText}
                    </small>
                  </div>
                  <div className="text-center">
                    {!showLoading && (
                      <Button
                        className="my-4"
                        color="primary"
                        type="submit"
                        onClick={() => onLoginAttempt()}
                      >
                        Sign in
                      </Button>
                    )}
                    {showLoading && <Spinner size="lg" color="primary" />}
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#fp"
                  onClick={() => setForgotModalOpen(!forgotModalOpen)}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a className="text-light" href="./register">
                  <small>Create new account</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={forgotModalOpen}
        toggle={() => setForgotModalOpen(!forgotModalOpen)}
      >
        <div className="modal-header">
          <h5 className="modal-title">Email me a password reset</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setForgotModalOpen(!forgotModalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-email-83" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Email"
                type="email"
                autoComplete="new-email"
                id="forgotPassInput"
                onChange={(e) => {
                  setForgotPassInput(e.target.value);
                }}
                value={forgotPassInput}
              />
            </InputGroup>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => setForgotModalOpen(!forgotModalOpen)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => sendPasswordReset()}
          >
            Send
          </Button>
        </div>
      </Modal>
    </>
  );
}
