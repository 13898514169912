import axios from "axios";

let APIBaseUrl = "https://lehmannsystems.dev";
let API = axios.create({
	
	baseURL: APIBaseUrl + "/fish/",
	responseType: "json",
	headers: {
		"Content-Type": "multipart/form-data",
		"X-EncId": localStorage.getItem("fishuid"),
		"X-EncEm": localStorage.getItem("fishuem"),
	},
});

export { API, APIBaseUrl };