import {
	faCloudSunRain,
	faSun,
	faCloudSun,
	faCloud,
	faCloudRain,
	faSnowflake,
	faWater,
	faUpDown,
	faFlag,
} from "@fortawesome/free-solid-svg-icons";
import {
	NewMoonIcon,
	WaningCrescentIcon,
	ThirdQuarterIcon,
	FullMoonIcon,
	WaxingGibbousIcon,
	FirstQuarterIcon,
	WaxingCrescentIcon,
} from "components/Icons/WeatherIcons";
let getWeatherClasses = (weather) => {
	let retData = {
		conditionBg: "bg-gradient-primary",
		conditionText: "text-white",
		conditionIcon: faCloudSunRain,
		waterBg: "bg-gradient-blue",
		waterIcon: faWater,
		pressureIcon: faUpDown,
		moonBg: "bg-gradient-dark",
		moonIcon: NewMoonIcon,
	};
	if (weather.weatherCondition.toLowerCase().indexOf("sun") > -1) {
		retData.conditionBg = "bg-gradient-yellow";
		retData.conditionText = "text-dark";
		retData.conditionIcon = faSun;
	} else if (
		weather.weatherCondition.toLowerCase().indexOf("partly cloudy") > -1
	) {
		retData.conditionBg = "bg-gradient-teal";
		retData.conditionIcon = faCloudSun;
	} else if (
		weather.weatherCondition.toLowerCase().indexOf("cloud") > -1 ||
		weather.weatherCondition.toLowerCase().indexOf("overcast") > -1 ||
		weather.weatherCondition.toLowerCase().indexOf("fog") > -1
	) {
		retData.conditionBg = "bg-gradient-gray";
		retData.conditionIcon = faCloud;
	} else if (
		weather.weatherCondition.toLowerCase().indexOf("mist") > -1 ||
		weather.weatherCondition.toLowerCase().indexOf("rain") > -1
	) {
		retData.conditionBg = "bg-gradient-gray-dark";
		retData.conditionIcon = faCloudRain;
	} else if (
		weather.weatherCondition.toLowerCase().indexOf("snow") > -1 ||
		weather.weatherCondition.toLowerCase().indexOf("ice") > -1 ||
		weather.weatherCondition.toLowerCase().indexOf("freez") > -1 ||
		weather.weatherCondition.toLowerCase().indexOf("sleet") > -1
	) {
		retData.conditionBg = "bg-gradient-gray-dark";
		retData.conditionIcon = faSnowflake;
	}

	if (parseFloat(weather.waveHeight) > 2.5) {
		retData.waterIcon = faFlag;
	}

	switch (weather.moonPhase) {
		default:
			retData.moonIcon = NewMoonIcon;
			break;
		case "Waning Crescent":
			retData.moonIcon = WaningCrescentIcon;
			break;
		case "Third Quarter":
			retData.moonIcon = ThirdQuarterIcon;
			break;
		case "Waning Gibbous":
			retData.moonIcon = ThirdQuarterIcon;
			break;
		case "Full Moon":
			retData.moonIcon = FullMoonIcon;
			break;
		case "Waxing Gibbous":
			retData.moonIcon = WaxingGibbousIcon;
			break;
		case "First Quarter":
			retData.moonIcon = FirstQuarterIcon;
			break;
		case "Waxing Crescent":
			retData.moonIcon = WaxingCrescentIcon;
			break;
	}
	return retData;
};

export { getWeatherClasses };
