import React from "react";
import { Modal } from "reactstrap";
import PhotoStep from "./AddCatchSteps/PhotoStep";
import LocationDateTimeStep from "./AddCatchSteps/LocationDateTimeStep";
import SpeciesStep from "./AddCatchSteps/SpeciesStep";
import StatsStep from "./AddCatchSteps/StatsStep";
import { API } from "../../services/API";
import LureStep from "./AddCatchSteps/LureStep";
import WeatherStep from "./AddCatchSteps/WeatherStep";
import { AdminContext } from "layouts/Admin";

export default function AddCatch({openFlag, onClose, reportMode}) {
	const [showModal, setShowModal] = React.useState(false);
	const [onStep, setOnStep] = React.useState(1);
	const [mapCenter, setMapCenter] = React.useState({lat:-1, lng:-1});
	const [submitData, setSubmitData] = React.useState({});
	const { showNotification, performPageReload } = React.useContext(AdminContext);
	React.useEffect(() => {
    	if (openFlag) {
			setSubmitData({});
			setShowModal(true);
			if (reportMode) {
				setOnStep(2);
			} else {
				setOnStep(1);
			}
			// API.get("catchFiles/getMapCenter.php")
			// 	.then((responseJson) => {
			// 		responseJson = responseJson.data;
			// 		console.log(responseJson);
			// 		if (
			// 			responseJson.data.latitude !== -1 ||
			// 			responseJson.data.longitude !== -1
			// 		) {
			// 			setMapCenter({
			// 					lat: responseJson.data.latitude,
			// 					lng: responseJson.data.longitude,
			// 				});
			// 		}
			// 	})
			// 	.catch((error) => {
			// 		console.log(error);
			// 	});
			navigator?.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
				setMapCenter({lat: lat, lng: lng});
			});
		}
  	}, [openFlag]);
	const toggle = () => {
		console.log('TGLER');
		setShowModal(!showModal);
		setSubmitData({});
		onClose();
	  };
	const saveNewCatch = (newData) => {
		const subData = new FormData();
		console.log(newData);
		for (const key in newData) {
			if (
				newData[key] !== undefined &&
				((typeof newData[key] === "string" &&
					newData[key].length > 0) ||
					typeof newData[key] !== "string")
			) {
			subData.append(key, newData[key]);
			}
		}
		subData.append('isFish', (reportMode ? 0 : 1))
		console.log(subData);
		API.post("catchFiles/catches.php", subData)
			.then((responseJson) => {
				responseJson = responseJson.data;
				
				if (responseJson.status) {
					// that.props.reloadPageData();
					performPageReload();
					toggle();
					if (reportMode) {
						showNotification(
							"success",
							"Recorded",
							"Taking notes will make you better"
						);
					} else {
						showNotification(
							"success",
							"Recorded",
							"Congrats on the fish!"
						);
					}
				} else {
					console.error('errrrrr');
				}
			})
			.catch((error) => {
				console.log(error);
				// setState({
				// 	showLoading: false,
				// 	errorText: "Error on our end. Try refreshing!",
				// });
			});
	};
	
	const previousStep = () => {
		handleStepChange(onStep - 1);
	};
	const nextStep = () => {
		handleStepChange(onStep + 1);
	};
	const handleStepChange = (newStep) => {
		if (newStep < 1) {
			setShowModal(false);
		} else if (reportMode && (newStep === 4 || newStep === 5)) {
			newStep = 6;
		}
		setOnStep(newStep);
	};
	const handleSubmitDataUpdate = (updateArray, saveToDb) => {
		console.log(updateArray);
		let newData = JSON.parse(JSON.stringify(submitData));
		console.log(newData);
		updateArray.forEach((updateDatapoint) => {
			newData[updateDatapoint.key] = updateDatapoint.val;
		});
		console.log(newData);
		setSubmitData(newData);
		if (saveToDb) {
			saveNewCatch(newData);
		}
	};
	
		return (
			<Modal
				className="modal-dialog-centered"
				isOpen={showModal}
				size="lg"
				backdrop={true}
				toggle={() => toggle()}
			>
				<div className="modal-header">
					<h5 className="modal-title">{reportMode ? 'New Report' : 'New Catch' }</h5>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => toggle()}
					>
						<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className="modal-body">
					{onStep === 1 && (
						<PhotoStep
							changeStep={(newStep) => handleStepChange(newStep)}
							nextStep={() => nextStep()}
							previousStep={() => previousStep()}
							onSave={(updateData) =>
								handleSubmitDataUpdate(updateData, false)
							}
							showNotification={showNotification}
						/>
					)}
					{onStep === 2 && (
						<LocationDateTimeStep
							changeStep={(newStep) => handleStepChange(newStep)}
							previousStep={() => previousStep()}
							nextStep={() => nextStep()}
							mapCenter={mapCenter}
							reportMode={reportMode}
							onSave={(updateData) =>
								handleSubmitDataUpdate(updateData, false)
							}
							showNotification={showNotification}
						/>
					)}
					{onStep === 3 && (
						<WeatherStep
							previousStep={() => previousStep()}
							nextStep={() => nextStep()}
							onSave={(updateData) =>
								handleSubmitDataUpdate(updateData, false)
							}
							showNotification={showNotification}
						/>
					)}
					{onStep === 4 && (
						<SpeciesStep
							nextStep={() => nextStep()}
							previousStep={() => previousStep()}
							onSave={(updateData) =>
								handleSubmitDataUpdate(updateData, false)
							}
						/>
					)}
					{onStep === 5 && (
						<LureStep
							nextStep={() => nextStep()}
							previousStep={() => previousStep()}
							onSave={(updateData) =>
								handleSubmitDataUpdate(updateData, false)
							}
						/>
					)}
					{onStep === 6 && (
						<StatsStep
							nextStep={() => nextStep()}
							previousStep={() => previousStep()}
							reportMode={reportMode}
							onSave={(updateData) =>
								handleSubmitDataUpdate(updateData, true)
							}
						/>
					)}
				</div>
			</Modal>
		);
}
