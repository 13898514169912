import React from "react";

export function NewMoonIcon({color}) {
		return (
			<svg
				version="1.1"
				id="Capa_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				width="107.112px"
				height="107.112px"
				viewBox="0 0 107.112 107.112"
				style={{
					fill: color,
					enableBackground: "new 0 0 107.112 107.112",
				}}
			>
				<g>
					<path
						d="M53.556,0C24.026,0,0,24.025,0,53.557c0,29.531,24.026,53.556,53.556,53.556s53.556-24.024,53.556-53.556
		C107.112,24.025,83.086,0,53.556,0z M53.556,106.362c-29.117,0-52.806-23.688-52.806-52.806C0.75,24.438,24.439,0.75,53.556,0.75
		c29.118,0,52.806,23.689,52.806,52.807C106.362,82.674,82.674,106.362,53.556,106.362z"
					/>
				</g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
			</svg>
		);
}

export function WaningCrescentIcon({color}) {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				height="1065pt"
				viewBox="-44 -33 1065 1065.0001"
				width="1065pt"
				style={{
					fill: color,
				}}
			>
				<path d="m973.386719 554.421875s-267.675781 264.9375-518.992188 87.199219c-354.453125-250.671875-10.328125-643.640625-10.328125-643.640625s-177.933594 11.847656-324.097656 168.292969c-81.546875 87.285156-122.730469 210.277343-122.730469 329.890624 0 133.617188 52.03125 259.242188 146.515625 353.734376 94.492188 94.484374 220.117188 146.519531 353.742188 146.519531 119.632812 0 236.914062-41.1875 326-120.8125 173.527344-155.109375 149.890625-321.183594 149.890625-321.183594zm0 0" />
			</svg>
		);
}

export function ThirdQuarterIcon({color}) {
		return (
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 512 512"
				style={{
					fill: color,
					enableBackground: "new 0 0 512 512",
				}}
			>
				<g>
					<path
						d="M128.1,255.9c0,46.4,11.4,89.4,34.3,128.5c23,39.1,53.9,70.5,93,93.3c39.1,22.7,82.1,34.3,128.5,34.3V0
		c-46.4,0-89.2,11.4-128.5,34.3c-39.3,23-70.1,53.9-93,93.3C139.4,166.9,128.1,209.5,128.1,255.9z"
					/>
				</g>
			</svg>
		);
}
export function FirstQuarterIcon({color}) {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 512 512"
				style={{
					fill: color,
					enableBackground: "new 0 0 512 512",
				}}
			>
				<g>
					<path
						d="M128.1,512c46.4,0,89.2-11.4,128.5-34.3c39.3-23,70.3-53.9,93-93.3c22.7-39.3,34.3-82.1,34.3-128.5s-11.4-89.2-34.3-128.5
		c-23-39.3-53.9-70.3-93-93C217.5,11.6,174.5,0,128.1,0V512z"
					/>
				</g>
			</svg>
		);
}
export function WaningGibbousIcon({color}) {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 512 512"
				style={{
					fill: color,
					enableBackground: "new 0 0 512 512",
				}}
			>
				<g>
					<path
						d="M41.8,255.9c0,27.9,4.3,54.8,12.8,80.9c8.5,26,20.8,49.6,36.6,70.6c15.8,21,34.3,39.3,55.3,55.3c21,16,44.5,28.1,70.6,36.6
		s53,12.8,80.9,12.8c114.9-32,172.3-117.4,172.3-256.3c0-27-3.2-52.5-9.6-77c-6.4-24.5-14.8-45.9-25.8-64.7
		c-11-18.7-23.8-35.9-38.4-51.2c-14.6-15.3-30.6-28.3-47.1-38.4C332.9,14.4,315.7,5.9,297.7,0c-34.7,0-68.1,6.9-99.8,20.3
		s-58.3,31.8-81.1,54.6s-41.1,50-54.6,81.6C48.7,188,41.8,221.2,41.8,255.9z"
					/>
				</g>
			</svg>
		);
}
export function FullMoonIcon({color}) {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 512 512"
				style={{
					fill: color,
					enableBackground: "new 0 0 512 512",
				}}
			>
				<g>
					<path
						d="M0,255.9c0,46.4,11.4,89.4,34.3,128.5c23,39.1,53.9,70.5,93.3,93.3c39.3,22.7,82.1,34.3,128.5,34.3s89.2-11.4,128.5-34.3
		c39.3-23,70.3-53.9,93-93.3c22.7-39.3,34.3-82.1,34.3-128.5s-11.4-89.2-34.3-128.5c-23-39.3-53.9-70.3-93-93
		C345.5,11.6,302.5,0,256.1,0s-89.2,11.6-128.5,34.3s-70.5,53.9-93.3,93C11.6,166.5,0,209.5,0,255.9z"
					/>
				</g>
			</svg>
		);
}
export function WaxingGibbousIcon({color}) {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 512 512"
				style={{
					fill: color,
					enableBackground: "new 0 0 512 512",
				}}
			>
				<g>
					<path
						d="M115.8,255.7c-0.5,103,7.5,188.6,23.7,256.3c46.4,0.2,89.4-11.2,128.5-33.9c39.1-22.7,70.5-53.7,93.5-92.8
		c23-39.1,34.6-82.1,34.8-128.5c0.2-46.4-11.1-89.4-33.9-128.5c-22.7-39.3-53.7-70.5-92.8-93.5C230.4,11.8,187.7,0.2,141.3,0
		C124.7,84.4,116,169.7,115.8,255.7z"
					/>
				</g>
			</svg>
		);
}
export function WaxingCrescentIcon({color}) {
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 512 512"
				style={{
					fill: color,
					enableBackground: "new 0 0 512 512",
				}}
			>
				<g>
					<path
						d="M128.1,512c46.4,0,89.2-11.4,128.5-34.3c39.3-23,70.3-53.9,93-93.3c22.7-39.3,34.3-82.1,34.3-128.5s-11.4-89.2-34.3-128.5
		c-23-39.3-53.9-70.3-93-93C217.5,11.6,174.5,0,128.1,0c30,11.8,56.4,27.3,78.9,46.9c22.5,19.6,40.5,40.7,53.4,64.1
		c13,23.4,22.5,47.1,28.9,71.2c6.4,24.1,9.3,48.7,9.3,73.7c0,14.5-0.5,28.7-1.6,41.8c-1.1,13.2-3.4,27.3-6.6,42.5
		c-3.2,15.2-7.5,29.1-12.7,42.3c-5.2,13.2-12.3,26.2-20.9,39.6c-8.7,13.4-18.9,25.2-30.7,35.9c-11.8,10.7-25.9,20.9-42.5,30.3
		C166.9,497.7,148.3,505.4,128.1,512z"
					/>
				</g>
			</svg>
		);
}

export function WindDirectionIcon({direction, color}) {
  const [rotate, setRotate] = React.useState('rotate(0deg)');
  React.useEffect(() => {
	let srotate = "rotate(";
		let deg = 0;
		if (direction === "W") {
			deg = 45;
		} else if (direction === "NW") {
			deg = 90;
		} else if (direction === "N") {
			deg = 135;
		} else if (direction === "NE") {
			deg = 180;
		} else if (direction === "E") {
			deg = 225;
		} else if (direction === "SE") {
			deg = 270;
		} else if (direction === "S") {
			deg = 315;
		}
		srotate = srotate + deg + "deg)";
		console.log(srotate);
		setRotate(srotate);
  },[]);
	
		return (
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 512 512"
				style={{
					fill: color,
					transform: rotate,
					enableBackground: "new 0 0 512 512",
				}}
			>
				<g>
					<path
						d="M504.7,7.3c3.2,3.2,5.4,6.3,6.5,9.5c1.2,3.2,0.8,7.5-1.2,13.1c-2,5.6-4,10.7-6,15.5s-5.8,12.5-11.3,23.2
		c-5.6,10.7-10.3,20.4-14.3,29.2C457.1,142.2,428,199,391.1,268s-68,127-93.4,173.8L258.3,512l-32.1-226.2L0,252.5
		c175.4-97.6,313.4-170.6,414.2-219c7.9-4,17.5-8.9,28.6-14.9c11.1-6,18.8-9.9,23.2-11.9s9.3-3.8,14.9-5.4
		c5.6-1.6,10.1-1.8,13.7-0.6C498.2,2,501.6,4.1,504.7,7.3 M450,64.4L269.1,231.1l16.7,139.3L450,64.4"
					/>
				</g>
			</svg>
		);
}
