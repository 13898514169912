import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Media,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import { AdminContext } from "layouts/Admin";
import { API } from "../../services/API";

export default function AnglerList({ reloadPageData }) {
  const [showLoading, setShowLoading] = React.useState(true);
  const [reloadTrigger, setReloadTrigger] = React.useState(1);
  const [followingList, setFollowingList] = React.useState({});
  const [suggestedList, setSuggestedList] = React.useState({});
  const { showNotification, showModal } = React.useContext(AdminContext);
  const { stripeReturn } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("session_id"));
  React.useEffect(() => {
    document.title = "Anglers";
    if (searchParams.get("session_id")) {
      showNotification(
        "success",
        "Payment Success",
        "Your subscription has been successfully processed. You are now following that angler!"
      );
      // showModal(
      //   "Payment Success",
      //   "Your subscription has been successfully processed. You can now access the premium features."
      // );
      API.get(
        "stripeFiles/status.php?session_id=" +
          searchParams.get("session_id") +
          "&plan_id=" +
          searchParams.get("plan_id")
      )
        .then((responseJson) => {
          responseJson = responseJson.data;
          getAnglerList();

          console.log(responseJson);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getAnglerList();
    }
    setReloadTrigger(reloadTrigger + 1);
  }, [reloadPageData]);
  const getAnglerList = () => {
    API.get("userFiles/getAnglerLists.php")
      .then((responseJson) => {
        responseJson = responseJson.data;
        console.log(responseJson);
        if (responseJson.status) {
          setFollowingList(responseJson.followingList);
          setSuggestedList(responseJson.othersList);
          setShowLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
  };
  return (
    <>
      <CardsHeader
        name="Angler List"
        parentName="Bite Logr"
        reloadTrigger={reloadTrigger}
      />
      <Container className="mt--6" fluid>
        {!showLoading && (
          <>
            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Anglers Following</h3>
                      </div>
                    </Row>
                  </CardHeader>
                  {followingList.length > 0 && (
                    <div className="table-responsive">
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th className="sort" data-sort="name" scope="col">
                              Angler
                            </th>
                            <th className="sort" data-sort="fish" scope="col">
                              Plan
                            </th>
                            <th className="sort" data-sort="size" scope="col">
                              Follow Start Date
                            </th>
                            <th className="sort" data-sort="color" scope="col">
                              Last Fish
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {followingList.map(function (angler, i) {
                            return (
                              <tr key={angler.userId}>
                                <th scope="row">{angler.name}</th>
                                <td>{angler.plan}</td>
                                <td className="size">{angler.startDate}</td>
                                <td>
                                  <span className="color">
                                    {angler.lastCatch}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Suggested Anglers</h3>
                      </div>
                    </Row>
                  </CardHeader>
                  {suggestedList.length > 0 && (
                    <div className="table-responsive">
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th className="sort" data-sort="name" scope="col">
                              Angler
                            </th>
                            <th className="sort" data-sort="fish" scope="col">
                              Top Species
                            </th>
                            <th className="sort" data-sort="color" scope="col">
                              Last Fish
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {suggestedList.map(function (angler, i) {
                            return (
                              <tr key={angler.userId}>
                                <th scope="row">
                                  <Link to={`../profile/${angler.userId}`}>
                                    {angler.name}
                                  </Link>
                                </th>
                                <td>{angler.topSpecies}</td>
                                <td>
                                  <span className="color">
                                    {angler.lastCatch}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </>
        )}
        {showLoading && (
          <div className="mt-6 text-center">
            <Spinner size="lg" color="primary" />
          </div>
        )}
      </Container>
    </>
  );
}
