import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	Col,
	Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WindDirectionIcon } from "components/Icons/WeatherIcons";

export default function WeatherSummaryCards({weatherClasses, weather}) {
	const MoonIcon = weatherClasses.moonIcon;
		return (
			<Card>
				<CardBody className="pb-0">
					<Row>
						<Col lg="3" className="d-flex">
							<Card
								className={`flex-fill border-0 ${weatherClasses.conditionBg}`}
							>
								<CardBody>
									<Row>
										<div className="col">
											<CardTitle
												className={`text-uppercase mb-0 ${weatherClasses.conditionText}`}
												tag="h5"
											>
												{weather.weatherCondition}
											</CardTitle>
											<span
												className={`h2 font-weight-bold mb-0  ${weatherClasses.conditionText}`}
											>
												{weather.airTemp}° F
											</span>
										</div>
										<Col className="col-auto">
											<div className="icon icon-shape bg-white text-dark rounded-circle shadow">
												<FontAwesomeIcon
													icon={weatherClasses.conditionIcon}
												/>
											</div>
										</Col>
									</Row>
									<p className="mt-3 mb-0 text-sm">
										<span
											className={`text-uppercase text-nowrap ${weatherClasses.conditionText}`}
										>
											{weather.cloudiness}% clouds
										</span>
									</p>
								</CardBody>
							</Card>
						</Col>
						<Col lg="3" className="d-flex">
							<Card className="border-0 bg-gradient-green flex-fill">
								<CardBody>
									<Row>
										<div className="col">
											<CardTitle
												className="text-uppercase mb-0 text-white"
												tag="h5"
											>
												Wind
											</CardTitle>
											<span className="h2 font-weight-bold mb-0 text-white">
												{weather.windSpeed}mph
											</span>
										</div>
										<Col className="col-auto">
											<div className="icon icon-shape bg-white text-dark rounded-circle shadow">
												<WindDirectionIcon
													color="black"
													direction={weather.windDirection}
												/>
											</div>
										</Col>
									</Row>
									<p className="mt-3 mb-0 text-sm">
										<span className="text-uppercase text-nowrap text-white">
											{weather.windDirection}
										</span>
									</p>
								</CardBody>
							</Card>
						</Col>
						<Col lg="3" className="d-flex">
							<Card className="border-0 bg-gradient-blue flex-fill">
								<CardBody>
									<Row>
										<div className="col">
											<CardTitle
												className="text-uppercase mb-0 text-white"
												tag="h5"
											>
												Pressure
											</CardTitle>
											<span className="h2 font-weight-bold mb-0 text-white">
												{weather.pressure} hPa
											</span>
										</div>
										<Col className="col-auto">
											<div className="icon icon-shape bg-white text-dark rounded-circle shadow">
												<FontAwesomeIcon
													icon={weatherClasses.pressureIcon}
												/>
											</div>
										</Col>
									</Row>
									{/* <p className="mt-3 mb-0 text-sm">
										<span className="text-uppercase text-nowrap text-white">
											{weather.waveHeight} ft waves
										</span>
									</p> */}
								</CardBody>
							</Card>
							{/* <Card className="border-0 bg-gradient-blue flex-fill">
								<CardBody>
									<Row>
										<div className="col">
											<CardTitle
												className="text-uppercase mb-0 text-white"
												tag="h5"
											>
												Water
											</CardTitle>
											<span className="h2 font-weight-bold mb-0 text-white">
												{weather.waterTemp}° F
											</span>
										</div>
										<Col className="col-auto">
											<div className="icon icon-shape bg-white text-dark rounded-circle shadow">
												<FontAwesomeIcon
													icon={weatherClasses.waterIcon}
												/>
											</div>
										</Col>
									</Row>
									<p className="mt-3 mb-0 text-sm">
										<span className="text-uppercase text-nowrap text-white">
											{weather.waveHeight} ft waves
										</span>
									</p>
								</CardBody>
							</Card> */}
						</Col>
						<Col lg="3" className="d-flex">
							<Card className="border-0 bg-gradient-default flex-fill">
								<CardBody>
									<Row>
										<div className="col">
											<CardTitle
												className="text-uppercase mb-0 text-white"
												tag="h5"
											>
												Moon Phase
											</CardTitle>
											<span className="h2 font-weight-bold mb-0 text-white">
												{weather.moonPhase}
											</span>
										</div>
										<Col className="col-auto">
											<div className="icon icon-shape bg-white text-dark rounded-circle shadow">
												<MoonIcon />
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}