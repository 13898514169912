import React from "react";
import {
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Button,
	Form,
	Row,
	Col,
	Spinner,
	Card,
	CardHeader,
	CardBody,
	ListGroupItem,
	ListGroup,
} from "reactstrap";
import { API } from "services/API";

export default function SpeciesStep({nextStep, previousStep, onSave}) {
	const [showLoading, setShowLoading] = React.useState(true);
	const [originalSpeciesArray, setOriginalSpeciesArray] = React.useState([]);
	const [displayedSpeciesArray, setDisplayedSpeciesArray] = React.useState([]);
	React.useEffect(() => {
    	API.get("speciesFiles/getSpecies.php")
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				setShowLoading(false);
				if (responseJson.status) {
					setOriginalSpeciesArray(responseJson.data);
					setDisplayedSpeciesArray(responseJson.data);
				} else {
					console.error('errrrr');
				}
			})
			.catch((error) => {
				console.log(error);
				setShowLoading(false);
			});
  	}, []);

	const handleSearchChange = (event) => {
		const searchValue = event.target.value.toLowerCase();
		let newSpeciesArray = [];
		originalSpeciesArray.forEach((species) => {
			if (
				searchValue.length < 1 ||
				species.name.toLowerCase().indexOf(searchValue) > -1
			) {
				newSpeciesArray.push(species);
			}
		});
		setDisplayedSpeciesArray(newSpeciesArray);
	};

	const speciesClicked = (speciesId) => {
		const subData = [
			{
				key: "species",
				val: speciesId,
			},
		];
		onSave(subData);
		nextStep();
	};
		return (
			<>
				<Card>
					<CardHeader className="py-0">
						<Form>
							<FormGroup className="mb-0">
								<InputGroup className="input-group-lg input-group-flush">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<span className="fas fa-search" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Search Species"
										type="search"
										onChange={(e) => {
											handleSearchChange(e);
										}}
									/>
								</InputGroup>
							</FormGroup>
						</Form>
					</CardHeader>
					<CardBody className="card-body-scroll-600">
						{!showLoading && (
							<ListGroup className="list my--3" flush>
								{displayedSpeciesArray.map(function (species, i) {
									return (
										<ListGroupItem className="px-0" key={species.id}>
											<Row className="align-items-center">
												<Col className="col-auto">
													<a
														className="avatar avatar-xl avatar-fish rounded-circle"
														href="#sp"
														onClick={(e) => {
															e.preventDefault();
															speciesClicked(species.id);
														}}
													>
														<img alt={species.name} src={species.image} />
													</a>
												</Col>
												<div className="col ml--2">
													<h4 className="mb-0">
														<a
															href="#spp"
															onClick={(e) => {
																e.preventDefault();
																speciesClicked(species.id);
															}}
														>
															{species.name}
														</a>
													</h4>
												</div>
												<Col className="col-auto">
													<Button
														color="primary"
														size="md"
														type="button"
														onClick={(e) => {
															e.preventDefault();
															speciesClicked(species.id);
														}}
													>
														Select
													</Button>
												</Col>
											</Row>
										</ListGroupItem>
									);
								})}
							</ListGroup>
						)}

						{showLoading && <Spinner size="lg" color="primary" />}
					</CardBody>
				</Card>
				<div className="modal-footer p-0">
					<Button
						color="secondary"
						data-dismiss="modal"
						type="button"
						onClick={() => previousStep()}
					>
						Back
					</Button>
					<Button
						color="primary"
						type="button"
						onClick={() => nextStep()}
					>
						Skip this step
					</Button>
				</div>
			</>
		);
	}
