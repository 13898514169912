import React from "react";
import { Card, Container, Row, Spinner } from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import GoogleMapReact from "google-map-react";
import { mapStyles, mapApiKey } from "variables/mapOptions.js";
import { API } from "services/API";
import CatchInfo from "components/Modals/CatchInfo";
import { AdminContext } from "layouts/Admin";

export default function FishMap() {
	const [showLoading, setShowLoading] = React.useState(true);
	const [reloadTrigger, setReloadTrigger] = React.useState(1);
	const [showCatchInfoModal, setShowCatchInfoModal] = React.useState(false);
	const [catchIdViewing, setCatchIdViewing] = React.useState(-1);
	const [waypoints, setWaypoints] = React.useState([]);
	const [mapCenter, setMapCenter] = React.useState({lat:-1,lng:-1});
	const [currentLocation, setCurrentLocation] = React.useState({lat:-1,lng:-1});
	const { showNotification } = React.useContext(AdminContext);
	const loadData = () => {
		const postData = new FormData();
		postData.append("hasLocation", 1);
		API.post("catchFiles/getCatches.php", postData)
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				if (responseJson.catches.length > 0) {
					setMapCenter({
						lat: parseFloat(responseJson.catches[0].latitude),
						lng: parseFloat(responseJson.catches[0].longitude),
					});
					setShowLoading(false);
				} else {
					getLocation(true);
				}
				setWaypoints(responseJson.catches);
			})
			.catch((error) => {
				console.log(error);
				setShowLoading(false);
			});
	};
	const getLocation = (setCenter) => {
		console.log('getting location');
		navigator?.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
			setShowLoading(false);
			setCurrentLocation({lat: lat, lng: lng});
			if (setCenter) {
				setMapCenter({lat: lat, lng: lng});
			}
		},
		() => {
			setShowLoading(false);
		});
	};
	React.useEffect(() => {
		document.title = 'Fish Map';
		loadData();
		getLocation(false);
		const localeInterval = setInterval(() => {
			getLocation(false);
		}, 5000);
		return () => clearInterval(localeInterval);
	}, []);

	const reloadCatches = () => {
		loadData();
		setReloadTrigger(reloadTrigger+1);
	};
	// const findCatchById = (catchId) => {
	// 	waypoints.forEach((fish) => {
	// 		if (fish.id === catchId) {
	// 			return fish;
	// 		}
	// 	});
	// };

	const onMarkerClick = (catchId) => {
		setCatchIdViewing(catchId);
		setShowCatchInfoModal(true);
	};

	// Marker component
	const Marker = ({catchId, isCurrentLocation, isFish}) => {
		if (isCurrentLocation) {
			const clMarkerStyle = {
				border: "1px solid white",
				borderRadius: "50%",
				fontSize: "1.2rem",
				color: "#f5365c",
				cursor: "pointer",
				zIndex: 10,
			};
			return <div style={clMarkerStyle} className="ni ni-single-02" />;
		} else {
			const color = isFish == 1 ? "#FB6340" : '#32325d';
			const fishMarkerStyle = {
				border: "1px solid white",
				borderRadius: "50%",
				height: 10,
				width: 10,
				backgroundColor: color,
				cursor: "pointer",
				zIndex: 10,
			};
			return <div style={fishMarkerStyle} onClick={() => {onMarkerClick(catchId)}}/>;
		}
	};

	const mapLoaded = (map, maps) => {
		console.log('MAP LADED');
		map.setOptions({
			styles: mapStyles,
		});
	};
		return (
			<>
				<CatchInfo
					openFlag={showCatchInfoModal}
					catchId={catchIdViewing}
					showNotification={showNotification}
					onClose={() => setShowCatchInfoModal(false)}
					reloadPageData={reloadCatches}
				/>
				<CardsHeader
					name="Fishing Map"
					parentName="Bite Logr"
					reloadTrigger={reloadTrigger}
				/>
				<Container className="mt--6" fluid>
					
					{!showLoading && (<Row>
						<div className="col">
							<Card
								className="shadow"
								style={{
									borderRadius: "0.375rem",
									height: "600px",
									border: "2px solid #F8F9FE",
								}}
							>
								<GoogleMapReact
									bootstrapURLKeys={{ key: mapApiKey }}
									center={[mapCenter.lat, mapCenter.lng]}
									defaultZoom={8}
									yesIWantToUseGoogleMapApiInternals
									onGoogleApiLoaded={({ map, maps }) =>
										mapLoaded(map, maps)
									}
									// onChildClick={onMarkerClick}
								>
									{waypoints.map(function (point, i) {
										return (
											<Marker
												key={point.id}
												lat={point.latitude}
												lng={point.longitude}
												catchId={point.id}
												isCurrentLocation = {false}
												show={true}
												isFish={point.isFish}
											/>
										);
									})}
									{currentLocation.lat !== -1 && (
										<Marker
												key={95859409498598493}
												lat={currentLocation.lat}
												lng={currentLocation.lng}
												catchId={-1}
												isCurrentLocation = {true}
												show={true}
												isFish={0}
											/>
									)}
								</GoogleMapReact>
							</Card>
						</div>
					</Row>) }
				</Container>

				{showLoading && (
					<div className="mt-6 text-center">
						<Spinner size="lg" color="primary" />
					</div>
				)}
			</>
		);
	}
