import React from "react";

import {
	Button,
	Card,
	CardHeader,
	Media,
	Progress,
	Table,
	Container,
	Row,
	Col,
	Spinner,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import { AdminContext } from "layouts/Admin";
import { API } from "../../services/API";

export default function TackleBox({reloadPageData}) {
	const [showLoading, setShowLoading] = React.useState(true);
	const [reloadTrigger, setReloadTrigger] = React.useState(1);
	const [currentTackleBox, setCurrentTackleBox] = React.useState({});
	const { showNotification, showModal } = React.useContext(AdminContext);
	React.useEffect(() => {
		document.title = 'Tackle Box';
		getCurrentTackleBox();
		setReloadTrigger(reloadTrigger+1);
	}, [reloadPageData]);
	const getCurrentTackleBox = () => {
		API.get("tackleBoxFiles/tackleBox.php?sort=1")
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				if (responseJson.status) {
					setShowLoading(false);
					setCurrentTackleBox(responseJson.data);
				}
			})
			.catch((error) => {
				console.log(error);
				setShowLoading(false);
			});
	};
	return (
			<>
				<CardsHeader
					name="Tackle Box"
					parentName="Bite Logr"
					reloadTrigger={reloadTrigger}
				/>
				<Container className="mt--6" fluid>
					{!showLoading && (
						<Row>
							<Col xl="12">
								<Card>
									<CardHeader className="border-0">
										<Row className="align-items-center">
											<div className="col">
												{currentTackleBox.length < 1 && (
													<h3>Your tackle box is empty &#128553;</h3>
												)}
												{currentTackleBox.length > 0 && (
													<h3 className="mb-0">My Lures</h3>
												)}
											</div>
											<div className="col text-right">
												<Button
													color="success"
													href="#pablo"
													onClick={(e) =>
														showModal(e, "showAddCustomLureModal")
													}
													size="sm"
												>
													Add Lure
												</Button>
											</div>
										</Row>
									</CardHeader>
									{currentTackleBox.length > 0 && (
										<div className="table-responsive">
											<Table className="align-items-center table-flush">
												<thead className="thead-light">
													<tr>
														<th className="sort" data-sort="name" scope="col">
															Lure
														</th>
														<th className="sort" data-sort="fish" scope="col">
															Fish Caught
														</th>
														<th className="sort" data-sort="size" scope="col">
															Size
														</th>
														<th className="sort" data-sort="color" scope="col">
															Color
														</th>
														<th scope="col" />
													</tr>
												</thead>
												<tbody className="list">
													{currentTackleBox.map(function (lure, i) {
														return (
															<tr key={lure.id}>
																<th scope="row">
																	<Media className="align-items-center" style={{cursor: 'pointer'}} onClick={(e) => e.preventDefault()}>
																		<span
																			className="avatar rounded-circle mr-3"
																			
																		>
																			<img
																				alt={lure.name}
																				src={lure.image.length > 4 ?lure.image : require("assets/img/brand/biteLogrLogos/3dLogoOnly.jpg")}
																				loading="lazy"
																			/>
																		</span>

																		<Media>
																			<span className="name mb-0 text-sm">
																				{lure.name}
																			</span>
																		</Media>
																	</Media>
																</th>
																<td>
																	<div className="d-flex align-items-center">
																		<span className="fish mr-2">
																			{lure.numberFish}
																		</span>
																		<div>
																			<Progress
																				max="30"
																				value={lure.numberFish}
																				color="warning"
																			/>
																		</div>
																	</div>
																</td>
																<td className="size">{lure.size}</td>
																<td>
																	<span className="color">{lure.color}</span>
																</td>
																<td className="text-right">
																	{/* <UncontrolledDropdown>
																		<DropdownToggle
																			color=""
																			size="sm"
																			className="btn-icon-only text-light"
																		>
																			<i className="fas fa-ellipsis-v" />
																		</DropdownToggle>
																		<DropdownMenu
																			className="dropdown-menu-arrow"
																			right
																		>
																			<DropdownItem
																				href="#pablo"
																				onClick={(e) => e.preventDefault()}
																			>
																				Analyze
																			</DropdownItem>
																			<DropdownItem
																				href="#pablo"
																				onClick={(e) => e.preventDefault()}
																			>
																				Delete
																			</DropdownItem>
																		</DropdownMenu>
																	</UncontrolledDropdown> */}
																</td>
															</tr>
														);
													})}
												</tbody>
											</Table>
										</div>
									)}
								</Card>
							</Col>
						</Row>
					)}
					{showLoading && (
						<div className="mt-6 text-center">
							<Spinner size="lg" color="primary" />
						</div>
					)}
				</Container>
			</>
		);
}