import React from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const redir = useNavigate();
	React.useEffect(() => {
		redir("/auth/home");
  });
  
  return (
    <>
      <div></div>
		</>
		);
};
