import React from "react";
import { Container, Row, Spinner } from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import CatchCard from "components/Fish/CatchCard";

import { API } from "services/API";
import { AdminContext } from "layouts/Admin";
import CatchInfo from "components/Modals/CatchInfo";
import MapModal from "components/Modals/MapModal";
import NoFishHelperCard from "components/Fish/NoFishHelperCard";

export default function HomeCatchList({ reloadPageData }) {
  const [showLoading, setShowLoading] = React.useState(false);
  const [reloadTrigger, setReloadTrigger] = React.useState(1);
  const [showCatchInfoModal, setShowCatchInfoModal] = React.useState(false);
  const [showCatchMapModal, setShowCatchMapModal] = React.useState(false);
  const [catchIdViewing, setCatchIdViewing] = React.useState(-1);
  const [catchObjectViewing, setCatchObjectViewing] = React.useState({});
  const [catchesArray, setCatchesArray] = React.useState([]);
  React.useEffect(() => {
    reloadCatches();
    document.title = "Catch List";
  }, [reloadPageData]);
  const { showNotification, showModal } = React.useContext(AdminContext);
  const reloadCatches = () => {
    getCatches();
    setReloadTrigger(reloadTrigger + 1);
  };

  const getCatches = () => {
    setShowLoading(true);
    API.post("catchFiles/getCatches.php")
      .then((responseJson) => {
        console.log(responseJson);
        setCatchesArray(responseJson.data.catches);
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
  };

  const onShowInfoClick = (catchId) => {
    setCatchIdViewing(catchId);
    setShowCatchInfoModal(true);
  };
  const onShowMapClick = (fish) => {
    setCatchObjectViewing(fish);
    setShowCatchMapModal(true);
  };
  return (
    <>
      <CatchInfo
        openFlag={showCatchInfoModal}
        catchId={catchIdViewing}
        onClose={() => setShowCatchInfoModal(false)}
        showNotification={showNotification}
        reloadPageData={reloadCatches}
      />
      <MapModal
        openFlag={showCatchMapModal}
        catchViewing={catchObjectViewing}
        showNotification={showNotification}
        onClose={() => setShowCatchMapModal(false)}
      />
      {!showLoading && catchesArray.length < 1 && (
        <NoFishHelperCard showModal={showModal}></NoFishHelperCard>
      )}
      {catchesArray.length > 0 && (
        <CardsHeader
          name="Catch List"
          parentName="Bite Logr"
          reloadTrigger={reloadTrigger}
        />
      )}
      <Container className="mt--6" fluid>
        {!showLoading && catchesArray.length > 0 && (
          <Row>
            {catchesArray.map(function (fish, i) {
              return (
                <CatchCard
                  key={fish.id}
                  datetime={fish.formattedTimestamp}
                  species={fish.species}
                  featuredImage={fish.featuredImage}
                  length={fish.length}
                  weight={fish.weight}
                  isFish={fish.isFish}
                  anglerName={fish.userName}
                  anglerProfileImage={fish.userPhoto}
                  disableMapBtn={
                    fish.latitude === "N/A" ||
                    fish.longitude === "N/A" ||
                    fish.latitude === "HIDDEN" ||
                    fish.longitude === "HIDDEN"
                  }
                  onShowInfoClick={() => onShowInfoClick(fish.id)}
                  onShowMapClick={() => onShowMapClick(fish)}
                ></CatchCard>
              );
            })}
          </Row>
        )}
      </Container>

      {showLoading && (
        <div className="mt-6 text-center">
          <Spinner size="lg" color="primary" />
        </div>
      )}
    </>
  );
}
