import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Spinner,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../services/API";
import CatchInfo from "components/Modals/CatchInfo";

export default function WeatherConditionCatches({
  weather,
  waterTemp,
  weatherClasses,
  showNotification,
}) {
  const [showLoading, setShowLoading] = React.useState(true);
  const [showCatchInfoModal, setShowCatchInfoModal] = React.useState(false);
  const [catchIdViewing, setCatchIdViewing] = React.useState(-1);
  const [catches, setCatches] = React.useState([]);
  const [airTempPlusMinus, setAirTempPlusMinus] = React.useState(5);

  React.useEffect(() => {
    if (weather.airTemp) {
      console.log("wcc updid");
      getCatches();
    }
  }, []);
  const getCatches = () => {
    setShowLoading(true);
    const subData = new FormData();
    subData.append("airTemp", weather.airTemp);
    subData.append("airTempPlusMinus", airTempPlusMinus);
    subData.append("waterTemp", waterTemp);
    subData.append("weatherCondition", weather.weatherCondition);
    API.post("catchFiles/getWeatherConditionCatches.php", subData)
      .then((responseJson) => {
        responseJson = responseJson.data;
        console.log(responseJson);
        setCatches(responseJson.catches);
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onShowInfoClick = (catchId) => {
    setCatchIdViewing(catchId);
    setShowCatchInfoModal(true);
  };

  return (
    <>
      <CatchInfo
        openFlag={showCatchInfoModal}
        catchId={catchIdViewing}
        onClose={() => setShowCatchInfoModal(false)}
        showNotification={showNotification}
        reloadPageData={getCatches}
      />
      <Card>
        <CardHeader
          className={`text-uppercase border-0 ${weatherClasses.conditionBg} `}
        >
          <Row>
            <Col>
              <span
                className="mr-4 icon icon-shape bg-white text-dark rounded-circle shadow"
                style={{
                  lineHeight: "48px",
                  verticalAlign: "middle",
                }}
              >
                <FontAwesomeIcon icon={weatherClasses.conditionIcon} />
              </span>
              <span className={`h4 mr-4 ${weatherClasses.conditionText}`}>
                {weather.weatherCondition}
              </span>
              <span
                className={`h2 font-weight-bold mr-4 ${weatherClasses.conditionText}`}
              >
                {weather.airTemp}° F
              </span>
              <span
                className={`text-uppercase text-nowrap ${weatherClasses.conditionText}`}
              >
                {weather.cloudiness}% clouds
              </span>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0 pt-1">
          {!showLoading && (
            <div className="table-responsive">
              <Table className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Angler
                    </th>
                    <th className="sort" data-sort="name" scope="col">
                      Lure
                    </th>
                    <th className="sort" data-sort="date" scope="col">
                      Date
                    </th>
                    <th className="sort" data-sort="color" scope="col">
                      Species
                    </th>
                    <th className="sort" data-sort="fish" scope="col">
                      Match Reasons
                    </th>
                    {/* <th scope="col" /> */}
                  </tr>
                </thead>
                <tbody className="list">
                  {catches.map(function (fish, i) {
                    return (
                      <tr
                        key={fish.id}
                        onClick={(e) => {
                          onShowInfoClick(fish.id);
                        }}
                        role="button"
                      >
                        <th scope="row">
                          <span className="name mb-0 text-sm">
                            {fish.userName}
                          </span>
                        </th>
                        <th scope="row">
                          {/* <Media className="align-items-center">
														<a
															className="avatar rounded-circle mr-3"
															href="#pablo"
															onClick={(e) => e.preventDefault()}
														>
															<img
																alt={lure.name}
																src={lure.image}
																loading="lazy"
															/>
														</a>
														<Media> */}
                          <span className="name mb-0 text-sm">
                            {fish.lureFullName}
                          </span>
                          {/* </Media>
													</Media> */}
                        </th>
                        <td className="date">{fish.formattedTimestamp}</td>
                        <td>
                          <span className="color">{fish.species}</span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="fish mr-2">
                              <ul>
                                {fish.reasons.map(function (reason, ri) {
                                  return <li key={ri}>{reason}</li>;
                                })}
                              </ul>
                            </span>
                          </div>
                        </td>
                        {/* <td className="text-right">
													<UncontrolledDropdown>
														<DropdownToggle
															color=""
															size="sm"
															className="btn-icon-only text-light"
														>
															<i className="fas fa-ellipsis-v" />
														</DropdownToggle>
														<DropdownMenu className="dropdown-menu-arrow" right>
															<DropdownItem
																href="#pablo"
																onClick={(e) => e.preventDefault()}
															>
																Analyze
															</DropdownItem>
															<DropdownItem
																href="#pablo"
																onClick={(e) => e.preventDefault()}
															>
																Delete
															</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
          {showLoading && (
            <div className="text-center">
              <Spinner size="lg" color="primary" />
              <h4>Getting matching catches...</h4>
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
}
