import React from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Collapse,
	Container,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";

function FAQ() {
  const [openedCollapses, setOpenedCollapses] = React.useState([]);

  React.useEffect(() => {
    document.title = 'FAQ';
		// eslint-disable-next-line
  	}, []);
	// with this function we create an array with the opened collapses
	// it is like a toggle function for all collapses from this page
	const collapsesToggle = collapse => {
		// let openedCollapses = this.state.openedCollapses;
		if (openedCollapses.includes(collapse)) {
      setOpenedCollapses([]);
		} else {
      setOpenedCollapses([collapse]);
		}
	};
		return (
			<>
				<AuthHeader title="FAQ" lead="" />
				<Container className="p-2 mt--8">
					<div className="accordion">
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseOne")}
              aria-expanded={openedCollapses.includes(
                "collapseOne"
              )}
            >
              <h5 className="mb-0">What should I do first?</h5>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={openedCollapses.includes("collapseOne")}
            >
              <CardBody>
                To get the most out of BiteLogr, we recommend adding lures to the app first. This will then allow you to assign any of your fish catches to the sepcific lure. <br/> We would then add some historical catches to BiteLogr. The more catches you have recorded, the better your analysis features will be. 
              </CardBody>
            </Collapse>
          </Card>
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseTwo")}
              aria-expanded={openedCollapses.includes(
                "collapseTwo"
              )}
            >
              <h5 className="mb-0">How easy is it to record a catch?</h5>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={openedCollapses.includes("collapseTwo")}
            >
              <CardBody>
                We wanted this to be as easy as possible to do on the water. If you catch a fish, just take a photo of it. When you upload the photo into BoiteLogr, we will automatically record the location of the photo, weather, moon phase, date and time of the catch. <br />You can then select the lure you caught it with and add any other information you might want like size of the fish, notes, status of the tide, trolling speed, etc.
              </CardBody>
            </Collapse>
          </Card>
		  <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseFour")}
              aria-expanded={openedCollapses.includes(
                "collapseFour"
              )}
            >
              <h5 className="mb-0">Is my data private?</h5>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={openedCollapses.includes("collapseFour")}
            >
              <CardBody>
                YES!<br/>We are fisherman and know how many years it takes to become a consistent fisherman. This is ALL experience and we are hoping BiteLogr can help you focus that experience to help you catch more in the future.<br/>
				So your data will be ONLY YOUR DATA. The data you upload will not be used to help others! On top of that, everything stored in our system is encrypted and tied to your unique password. No one will be able to see your catches or learn anything from them.
              </CardBody>
            </Collapse>
          </Card>
          <Card className="card-plain">
            <CardHeader
              role="tab"
              onClick={() => collapsesToggle("collapseThree")}
              aria-expanded={openedCollapses.includes(
                "collapseThree"
              )}
            >
              <h5 className="mb-0">What features are coming up?</h5>
            </CardHeader>
            <Collapse
              role="tabpanel"
              isOpen={openedCollapses.includes("collapseThree")}
            >
              <CardBody>
                We have a lot in mind!
				<ul>
					<li>
						We have some simple analysis features right now that allow you to see your top lures, locations and weather patterns. But we are taking that a step further by using your past catches to help us make recommendations to you. Is it cloudy right now? Maybe you should throw a green bait because you catch a lot of fish with green lures in cloudy conditons. Maybe you can hone in on a depth based on current weather and water conditions? The possibilities here are pretty endless.
					</li>
					<li>
						Native phone apps are an obvious gap we have here. While BiteLogr.app will currently work great in a phone web browser, we know there is value in a native app.
					</li>
				</ul>
              </CardBody>
            </Collapse>
          </Card>
        </div>
				</Container>
			</>
		);
}

export default FAQ;
