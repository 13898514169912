import React from "react";
import { Button, Modal, Table, Spinner } from "reactstrap";
import { API } from "../../services/API";
import { useNavigate } from "react-router-dom";

export default function OfferedPlansTable({
  reloadPageData,
  userId,
  publicVersion,
}) {
  const [showLoading, setShowLoading] = React.useState(false);
  const [confirmationModal, setConfirmationModal] = React.useState(false);
  const [confirmPlan, setConfirmPlan] = React.useState("");
  const [confirmPlanId, setConfirmPlanId] = React.useState(-1);
  const [plansTable, setPlansTable] = React.useState([]);
  const [isMyTable, setIsMyTable] = React.useState(false);
  const [plansTableHeader, setPlansTableHeader] = React.useState([]);
  const redir = useNavigate();

  React.useEffect(() => {
    getPlans();
    console.log(userId);
    setIsMyTable(userId === localStorage.getItem("fishuid") || userId == -1);
  }, [reloadPageData]);

  const getPlans = () => {
    setShowLoading(true);
    API.get("planFiles/getPlansTable.php?userId=" + userId)
      .then((responseJson) => {
        responseJson = responseJson.data;
        setPlansTable(responseJson.data);
        setPlansTableHeader(responseJson.headers);
        console.log(responseJson);
        setShowLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoading(false);
      });
  };

  const subToPlan = (planId, planName) => {
    console.log(planId);
    setConfirmPlan(planName);
    setConfirmPlanId(planId);
    if (publicVersion) {
      redir("/auth/register?rdr=prf_" + userId);
    } else {
      setConfirmationModal(true);
    }
  };

  const confirmSub = () => {
    API.get("stripeFiles/checkout.php?planId=" + confirmPlanId).then(
      (responseJson) => {
        console.log(responseJson.data);
        window.location.href = responseJson.data.checkoutUrl;
      }
    );
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Are you sure?
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setConfirmationModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            You will see catch data for this angler from while you are
            subscribed and 3 weeks back.
          </p>
          <p>
            Your credit card will be charged monthly for access to this angler's{" "}
            {confirmPlan} plan. Are you sure you want to subscribe?
          </p>
          <p>** You will be redirected to Stripe to complete payment **</p>
        </div>
        <div className="modal-footer">
          <Button color="success" type="button" onClick={() => confirmSub()}>
            Yes
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setConfirmationModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      {!showLoading && (
        <Table className=" mt-5" responsive>
          <thead>
            {!isMyTable && (
              <tr>
                {plansTableHeader.map(function (head, i) {
                  if (i > 0) {
                    return (
                      <th className="text-center" scope="col" key={i}>
                        <Button
                          color="primary"
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            subToPlan(head[0], head[1]);
                          }}
                          size="sm"
                        >
                          Subscribe
                        </Button>
                      </th>
                    );
                  } else return <th key={i}></th>;
                })}
              </tr>
            )}
            <tr>
              {plansTableHeader.map(function (head, i) {
                return (
                  <th
                    className={
                      i == 0 ? "font-weight-700" : "font-weight-700 text-center"
                    }
                    scope="col"
                    key={i}
                  >
                    {head[1]}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {plansTable.map(function (tbrow, k) {
              return (
                <tr key={k}>
                  {tbrow.map(function (tbcol, j) {
                    if (j == 0) {
                      return (
                        <td key={k + "_" + j} className="px-0">
                          {tbcol}
                        </td>
                      );
                    }
                    if (tbcol == "true" || tbcol == "false") {
                      return (
                        <td key={k + "_" + j} className="text-center">
                          <i
                            className={
                              tbcol == "true"
                                ? "fas fa-check text-success"
                                : "fas fa-x text-warning"
                            }
                          />
                        </td>
                      );
                    }
                    return (
                      <td className="text-center" key={k + "_" + j}>
                        <span className="text-sm">{tbcol}</span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {showLoading && (
        <div className="mt-6 text-center">
          <Spinner size="lg" color="primary" />
        </div>
      )}
    </>
  );
}
