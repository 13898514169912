import React from "react";
import { Button, FormGroup, Input, Label, Form, Col } from "reactstrap";
import Select2 from "react-select2-wrapper";

export default function StatsStep({ previousStep, onSave, reportMode }) {
  const [length, setLength] = React.useState("");
  const [weightLbs, setWeightLbs] = React.useState("");
  const [weightOz, setWeightOz] = React.useState("");
  const [waterDepth, setWaterDepth] = React.useState("");
  const [waterTemp, setWaterTemp] = React.useState("");
  const [waterClarity, setWaterClarity] = React.useState("");
  const [speed, setSpeed] = React.useState("");
  const [tide, setTide] = React.useState("");
  const [notes, setNotes] = React.useState("");

  const saveStats = () => {
    let weight = weightLbs;
    if (!isNaN(parseFloat(weight)) && !isNaN(parseFloat(weightOz))) {
      weight = weight + parseFloat(weightOz) * 0.0625;
    } else if (!isNaN(parseFloat(weightOz))) {
      weight = parseFloat(weightOz) * 0.0625;
    }
    onSave([
      {
        key: "length",
        val: length,
      },
      {
        key: "weight",
        val: weight,
      },
      {
        key: "waterDepth",
        val: waterDepth,
      },
      {
        key: "waterTemp",
        val: waterTemp,
      },
      {
        key: "waterClarity",
        val: waterClarity,
      },
      {
        key: "tide",
        val: tide,
      },
      {
        key: "speed",
        val: speed,
      },
      {
        key: "notes",
        val: notes,
      },
    ]);
  };

  return (
    <>
      <Form>
        {!reportMode && (
          <>
            <FormGroup className="row">
              <Label className="form-control-label" htmlFor="length" md="2">
                Length
              </Label>
              <Col md="10">
                <Input
                  placeholder="inches"
                  id="length"
                  type="text"
                  onChange={(e) => {
                    setLength(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Label className="form-control-label" htmlFor="weightLbs" md="2">
                Weight (lb/oz)
              </Label>
              <Col md="6">
                <Input
                  placeholder="pounds"
                  id="weightLbs"
                  type="text"
                  onChange={(e) => {
                    setWeightLbs(e.target.value);
                  }}
                />
              </Col>
              <Col md="4">
                <Input
                  placeholder="oz"
                  type="text"
                  id="weightOz"
                  onChange={(e) => {
                    setWeightOz(e.target.value);
                  }}
                />
              </Col>
            </FormGroup>
          </>
        )}
        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="waterTemp" md="2">
            Water Temp
          </Label>
          <Col md="10">
            <Input
              placeholder="degrees"
              id="waterTemp"
              type="text"
              onChange={(e) => {
                setWaterTemp(e.target.value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="waterDepth" md="2">
            Water Depth
          </Label>
          <Col md="10">
            <Input
              placeholder="fow"
              id="waterDepth"
              type="text"
              onChange={(e) => {
                setWaterDepth(e.target.value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="tide" md="2">
            Water Clarity
          </Label>
          <Col md="10">
            <Select2
              value={waterClarity}
              className="form-control"
              data-minimum-results-for-search="Infinity"
              id="waterClarity"
              options={{
                placeholder: "Select...",
              }}
              onSelect={(e) => {
                setWaterClarity(e.target.value);
              }}
              data={[
                { id: "Clear", text: "Clear" },
                { id: "Lightly Stained", text: "Lightly Stained" },
                { id: "Stained", text: "Stained" },
                { id: "Muddy", text: "Muddy" },
              ]}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="tide" md="2">
            Tide
          </Label>
          <Col md="10">
            <Select2
              value={tide}
              className="form-control"
              data-minimum-results-for-search="Infinity"
              id="tide"
              options={{
                placeholder: "Select...",
              }}
              onSelect={(e) => {
                setTide(e.target.value);
              }}
              data={[
                { id: "Incoming", text: "Incoming" },
                { id: "Outgoing", text: "Outgoing" },
                { id: "Slack", text: "Slack" },
              ]}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="speed" md="2">
            Speed
          </Label>
          <Col md="10">
            <Input
              placeholder="for trollers"
              id="speed"
              type="text"
              onChange={(e) => {
                setSpeed(e.target.value);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label className="form-control-label" htmlFor="notes" md="2">
            Notes
          </Label>
          <Col md="10">
            <Input
              placeholder={
                reportMode
                  ? "notes you want about the day of fishing"
                  : "depth of fish, retrieve or tactic notes"
              }
              id="notes"
              resize="none"
              rows="3"
              type="textarea"
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </Col>
        </FormGroup>
      </Form>
      <div className="modal-footer p-0">
        <Button color="secondary" type="button" onClick={() => previousStep()}>
          Back
        </Button>
        <Button color="primary" type="button" onClick={() => saveStats()}>
          Finish
        </Button>
      </div>
    </>
  );
}
