import React from "react";
// react library for routing
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NotificationAlert from "react-notification-alert";
import AddCustomLure from "components/Modals/AddCustomLure";
import FishMap from "views/LoggedIn/FishMap";
import HomeCatchList from "views/LoggedIn/HomeCatchList";
import TackleBox from "views/LoggedIn/TackleBox";
import AnglerList from "views/LoggedIn/AnglerList";
import TripPlanner from "views/LoggedIn/TripPlanner";
import Profile from "views/LoggedIn/Profile";
import SetupPlans from "views/LoggedIn/SetupPlans";
import {
  faMapMarkedAlt,
  faList,
  faToolbox,
  faCloudSun,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import AddCatch from "components/Modals/AddCatch";

export const AdminContext = React.createContext();
if (window.innerWidth < 1200) {
  // toggleSidenav();
  document.body.classList.remove("g-sidenav-pinned");
  document.body.classList.add("g-sidenav-hidden");
  // setSidenavOpen(false);
}

function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const [showAddCustomLureModal, setShowAddCustomLureModal] =
    React.useState(false);
  const [showAddCatchModal, setShowAddCatchModal] = React.useState(false);
  const [addReport, setAddReport] = React.useState(false);
  const [reloadPageData, setReloadPageData] = React.useState(1);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const notificationAlertRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    if (window.innerWidth < 1200) {
      // toggleSidenav();
      // document.body.classList.remove("g-sidenav-pinned");
      // document.body.classList.add("g-sidenav-hidden");
      setSidenavOpen(false);
    }
  }, [location]);
  const routes = [
    {
      path: "/homecatchlist",
      name: "Catch List",
      miniName: "CL",
      faIcon: faList,
      colorClass: "text-primary",
      component: <HomeCatchList reloadPageData={reloadPageData} />,
      layout: "/admin",
      sidebar: true,
    },
    {
      path: "/anglerlist/:stripeReturn?",
      name: "Anglers",
      miniName: "ANG",
      faIcon: faUsers,
      colorClass: "text-primary",
      component: <AnglerList reloadPageData={reloadPageData} />,
      layout: "/admin",
      sidebar: true,
    },
    {
      path: "/fishmap",
      name: "Fish Map",
      miniName: "FM",
      faIcon: faMapMarkedAlt,
      colorClass: "text-green",
      component: <FishMap reloadPageData={reloadPageData} />,
      layout: "/admin",
      sidebar: true,
    },

    {
      path: "/tacklebox",
      name: "My Tackle Box",
      miniName: "TB",
      faIcon: faToolbox,
      colorClass: "text-primary",
      component: <TackleBox reloadPageData={reloadPageData} />,
      layout: "/admin",
      sidebar: true,
    },
    {
      path: "/tripplanner",
      name: "Trip Planner",
      miniName: "TP",
      faIcon: faCloudSun,
      colorClass: "text-orange",
      component: <TripPlanner reloadPageData={reloadPageData} />,
      layout: "/admin",
      sidebar: true,
    },
    {
      path: "/profile/:userId?",
      name: "Profile",
      miniName: "PRO",
      faIcon: faCloudSun,
      colorClass: "text-orange",
      component: <Profile reloadPageData={reloadPageData} />,
      layout: "/admin",
      sidebar: false,
    },
    {
      path: "/setupplans",
      name: "Make Money",
      miniName: "SPLA",
      faIcon: faCloudSun,
      colorClass: "text-orange",
      component: <SetupPlans reloadPageData={reloadPageData} />,
      layout: "/admin",
      sidebar: false,
    },
  ];

  const performPageReload = () => {
    console.log("PPRLOAD");
    setReloadPageData(reloadPageData + 1);
  };

  const showModal = (e, modal) => {
    // e.preventDefault();
    if (modal === "showAddCustomLureModal") {
      setShowAddCustomLureModal(true);
    } else if (modal === "showAddCatchModal") {
      setAddReport(false);
      setShowAddCatchModal(true);
    } else if (modal === "showAddReportModal") {
      setAddReport(true);
      setShowAddCatchModal(true);
    }
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  const showNotification = (type, title, message, duration = 7) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: duration,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <AdminContext.Provider
        value={{ showNotification, showModal, performPageReload }}
      >
        <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <AddCustomLure
          openFlag={showAddCustomLureModal}
          onClose={() => setShowAddCustomLureModal(false)}
        />
        <AddCatch
          openFlag={showAddCatchModal}
          reportMode={addReport}
          onClose={() => setShowAddCatchModal(false)}
        />
        <Sidebar
          routes={routes}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: "/admin/homecatchlist",
            imgSrc: require("assets/img/brand/biteLogrLogos/LogoHorizontal.png"),
            imgAlt: "...",
          }}
        />
        <div className="main-content" ref={mainContentRef}>
          <AdminNavbar
            theme={getNavbarTheme()}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
            showModal={showModal}
          />
          <Routes>
            {getRoutes(routes)}
            <Route
              path="*"
              element={<Navigate to="/admin/dashboard" replace />}
            />
          </Routes>
          <AdminFooter />
        </div>
        {sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={toggleSidenav} />
        ) : null}
      </AdminContext.Provider>
    </>
  );
}

export default Admin;
