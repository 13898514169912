import React from "react";
import {
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Button,
	Form,
	Row,
	Col,
	Spinner,
	Card,
	CardHeader,
	CardBody,
	ListGroupItem,
	ListGroup,
} from "reactstrap";
import { API } from "../../../services/API";

export default function LureStep({previousStep, nextStep, onSave}) {
	const [showLoading, setShowLoading] = React.useState(false);
	const [originalTackleBoxArray, setOriginalTackleBoxArray] = React.useState([]);
	const [displayedTackleBoxArray, setDisplayedTackleBoxArray] = React.useState([]);
	React.useEffect(() => {
    	API.get("tackleBoxFiles/tackleBox.php?sort=0")
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				setShowLoading(false);
				if (responseJson.status) {
					setOriginalTackleBoxArray(responseJson.data);
					setDisplayedTackleBoxArray(responseJson.data);
					
				} else {
					console.error("Error on our end. Try refreshing!");
				}
			})
			.catch((error) => {
				console.log(error);
				setShowLoading(false);
			});
  	}, []);

	const handleSearchChange = (event) => {
		const searchValue = event.target.value.toLowerCase();
		let newTbArray = [];
		originalTackleBoxArray.forEach((lure) => {
			if (
				searchValue.length < 1 ||
				lure.name.toLowerCase().indexOf(searchValue) > -1
			) {
				newTbArray.push(lure);
			}
		});
		setDisplayedTackleBoxArray(newTbArray);
	};

	const lureClicked = (lureId) => {
		const subData = [
			{
				key: "lure",
				val: lureId,
			},
		];
		onSave(subData);
		nextStep();
	};

		return (
			<>
				<Card>
					<CardHeader className="py-0">
						<Form>
							<FormGroup className="mb-0">
								<InputGroup className="input-group-lg input-group-flush">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<span className="fas fa-search" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Search Tackle Box"
										type="search"
										onChange={(e) => {
											handleSearchChange(e);
										}}
									/>
								</InputGroup>
							</FormGroup>
						</Form>
					</CardHeader>
					<CardBody className="card-body-scroll-600">
						{!showLoading && (
							<ListGroup className="list my--3" flush>
								{displayedTackleBoxArray.map(function (lure, i) {
									return (
										<ListGroupItem className="px-0" key={lure.id}>
											<Row className="align-items-center">
												<Col className="col-auto">
													<a
														className="avatar avatar-xl avatar-fish rounded-circle"
														href="#sp"
														onClick={(e) => {
															e.preventDefault();
															lureClicked(lure.id);
														}}
													>
														{lure.image.length > 2 && (<img alt={lure.name} src={lure.image} />)}
														{lure.image.length < 2 && (<img alt={lure.name} src={require("assets/img/brand/biteLogrLogos/LogoOnly.png")} />)}
														
													</a>
												</Col>
												<div className="col ml--2">
													<h4 className="mb-0">
														<a
															href="#spp"
															onClick={(e) => {
																e.preventDefault();
																lureClicked(lure.id);
															}}
														>
															{lure.name}
														</a>
													</h4>
												</div>
												<Col className="col-auto">
													<Button
														color="primary"
														size="md"
														type="button"
														onClick={(e) => {
															e.preventDefault();
															lureClicked(lure.id);
														}}
													>
														Select
													</Button>
												</Col>
											</Row>
										</ListGroupItem>
									);
								})}
							</ListGroup>
						)}

						{showLoading && <Spinner size="lg" color="primary" />}
					</CardBody>
				</Card>
				<div className="modal-footer p-0">
					<Button
						color="secondary"
						data-dismiss="modal"
						type="button"
						onClick={() => previousStep()}
					>
						Back
					</Button>
					<Button
						color="primary"
						type="button"
						onClick={() => nextStep()}
					>
						Skip this step
					</Button>
				</div>
			</>
		);
	}
