import React from "react";
import {
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Button,
	Form,
	Label,
	Spinner,
} from "reactstrap";
import ReactDatetime from "react-datetime";

import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { mapStyles, mapApiKey } from "variables/mapOptions.js";
import { API } from "services/API";

function LocationDateTimeStep({mapCenter, showNotification, changeStep, onSave, nextStep, previousStep, reportMode, google}) {
	const [showLoading, setShowLoading] = React.useState(false);
	const [clickMarker, setClickMarker] = React.useState({lat:0,lng:0});
	// const [lat, setLat] = React.useState(-1);
	// const [lng, setLng] = React.useState(-1);
	const [dateTime, setDateTime] = React.useState(Date.now());
	React.useEffect(() => {
		setClickMarker(mapCenter);
        // setMapCenter({
        //     lat: catchViewing.latitude,
        //     lng: catchViewing.longitude
        // });
        // if (openFlag) {
        //     setShowModal(true);
        //     // getLocation();
        // }
    }, [mapCenter]);

	const mapLoaded = (mapProps, map) => {
		map.setOptions({
			styles: mapStyles,
		});
	};
	const mapClicked = (mapProps, map, event) => {
		setClickMarker(event.latLng.toJSON());
	};

	const saveStep = () => {
		let newDt = 0;
		if (dateTime._isAMomentObject) {
			newDt = dateTime.format("X");
		} else {
			newDt = parseInt(dateTime / 1000);
		}
		let saveData = [
			{
				key: "latitude",
				val: clickMarker.lat,
			},
			{
				key: "longitude",
				val: clickMarker.lng,
			},
			{
				key: "timestamp",
				val: newDt,
			},
		];
		setShowLoading(true);
		const subData = new FormData();
		subData.append("timestamp", newDt);
		subData.append("latitude", clickMarker.lat);
		subData.append("longitude", clickMarker.lng);
		API.post("catchFiles/getWeather.php", subData)
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				if (responseJson.status) {
					if (responseJson.data.airTemp) {
						showNotification(
							"default",
							"Great!",
							"We were able to get the weather for that time and location"
						);
						for (const property in responseJson.data) {
							saveData.push({
								key: property,
								val: responseJson.data[property],
							});
						}
						onSave(saveData);
						changeStep(4);
					} else {
						showNotification(
							"warning",
							"Ummmm",
							"We were unable to get the weather for that time and location"
						);
						onSave(saveData);
						nextStep();
					}
				} else {
					onSave(saveData);
					nextStep();
				}
			})
			.catch((error) => {
				console.log(error);
				// this.setState({
				// 	showLoading: false,
				// 	errorText: "Error on our end. Try refreshing!",
				// });
			});
	};


		return (
			<>
				{!showLoading && (
					<>
						<h3>
							Click to select the location of your {reportMode ? 'report' : 'catch'} on the map (
							<a
								href="#sk"
								className="text-primary"
								onClick={() => {
									saveStep(false);
								}}
							>
								Skip
							</a>
							)
						</h3>
					<Form>
						<FormGroup>
							<Label className="form-control-label">Date/Time</Label>
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className="ni ni-calendar-grid-58" />
									</InputGroupText>
								</InputGroupAddon>
								<ReactDatetime
									value={dateTime}
									inputProps={{
										placeholder: "Select Date and Time",
									}}
									onChange={(newDt) => {
										setDateTime(newDt);
									}}
								/>
							</InputGroup>
						</FormGroup>
					</Form>
				
						<div style={{ height: "400px", width: "100%" }}>
							<Map
								google={google}
								zoom={14}
								center={mapCenter}
								initialCenter={mapCenter}
								mapTypeControl={false}
								streetViewControl={false}
								containerStyle={{
									height: "100%",
									position: "relative",
									width: "100%",
								}}
								onReady={(mapProps, map) => mapLoaded(mapProps, map)}
								onClick={mapClicked}
							>
								<Marker
									position={clickMarker}
                            	/>
								{/* <Marker
									lat={clickMarker.lat}
									lng={clickMarker.lng}
									show={
										clickMarker.lat !== 0 ||
										clickMarker.lng !== 0
									}
									textForInfoWindow="Set this as where the fish was caught?"
									setLocationFromMap={(e) => {
										setLocationFromMap(e);
									}}
									hideMarkerAndInfoWindow={(e) => {
										hideMarkerAndInfoWindow(e);
									}}
								/> */}
							</Map>
						</div>
					</>
				)}
				{showLoading && (
					<div className="text-center">
						<Spinner size="lg" color="primary" />
						<h4>Attempting to get weather...</h4>
					</div>
				)}

				<div className="modal-footer p-0">
					<Button
						disabled={showLoading}
						color="secondary"
						type="button"
						onClick={() => previousStep()}
					>
						Back
					</Button>
					<Button
						disabled={showLoading}
						color="primary"
						type="button"
						onClick={() => saveStep()}
					>
						Next
					</Button>
				</div>
			</>
		);
	}
export default GoogleApiWrapper({
    apiKey: mapApiKey,
})(LocationDateTimeStep);