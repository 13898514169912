import React from "react";

export default function LureIcon({color}) {

		return (
			<svg
				id="Capa_1"
				enableBackground="new 0 0 512 512"
				height="512"
				viewBox="0 0 512 512"
				width="512"
				style={{
					fill: color,
				}}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					<path d="m378.714 384.25c0 15.932-12.961 28.893-28.893 28.893h-6.546v30h6.546c10.267 0 19.928-2.64 28.341-7.278-3.756 26.052-26.229 46.135-53.305 46.135-29.697 0-53.857-24.16-53.857-53.857v-56.122c18.336-5.619 34.698-21.591 47.077-46.366 14.018-28.052 21.737-65.017 21.737-104.083s-7.72-76.031-21.737-104.083c-7.349-14.707-16.1-26.315-25.834-34.496 8.176-8.822 13.186-20.616 13.186-33.563 0-27.256-22.174-49.43-49.429-49.43s-49.429 22.174-49.429 49.429c0 12.947 5.01 24.742 13.186 33.563-9.735 8.181-18.486 19.789-25.834 34.496-14.018 28.052-21.737 65.017-21.737 104.083s7.72 76.031 21.737 104.083c12.38 24.775 28.741 40.747 47.077 46.366v56.122c0 29.698-24.16 53.858-53.857 53.858-27.075 0-49.549-20.083-53.305-46.135 8.413 4.638 18.074 7.278 28.341 7.278h8.547v-30h-8.547c-15.932 0-28.893-12.961-28.893-28.893h-30v43.893c0 46.239 37.618 83.857 83.856 83.857 28.493 0 53.696-14.296 68.857-36.078 15.162 21.782 40.366 36.078 68.858 36.078 46.238 0 83.856-37.618 83.856-83.857v-43.893zm-142.143-334.821c0-10.713 8.716-19.429 19.429-19.429s19.429 8.716 19.429 19.429-8.716 19.428-19.429 19.428-19.429-8.715-19.429-19.428zm-15.812 81.468c10.175-20.362 23.02-32.041 35.241-32.041s25.066 11.678 35.241 32.041c6.23 12.467 10.988 27.174 14.135 43.261-8.473 19.531-27.861 32.413-49.377 32.413-21.515 0-40.903-12.881-49.377-32.412 3.148-16.087 7.907-30.794 14.137-43.262zm0 181.348c-11.978-23.969-18.573-56.171-18.573-90.674 0-1.525.029-3.039.054-4.554 14.762 12.335 33.665 19.554 53.76 19.554s38.998-7.219 53.76-19.554c.026 1.515.054 3.029.054 4.554 0 34.502-6.596 66.705-18.573 90.674-10.175 20.362-23.02 32.041-35.241 32.041s-25.066-11.679-35.241-32.041z" />
					<path d="m240.917 137.714h30.083v30h-30.083z" />
				</g>
			</svg>
		);
}
