import React from "react";

export default function FishIcon({color}) {
		return (
			<svg
				id="Capa_1"
				enableBackground="new 0 0 512.159 512.159"
				height="512"
				viewBox="0 0 512.159 512.159"
				width="512"
				xmlns="http://www.w3.org/2000/svg"
				style={{
					fill: color,
				}}
			>
				<g>
					<path d="m133.449 40.857c16.043 3.608 27.857 9.227 36.79 15.726 39.264-10.897 84.758-15.284 127.739-11.067-43.62-49.27-104.192-55.259-166.271-33.604-14.936 5.21-12.968 25.979 1.742 28.945z" />
					<path d="m69.745 311.815c29.804-26.011 63.767-31.961 100.947-17.684 34.534 13.689 91.011 29.856 142.513 38.154-3.714-5.842-3.746-5.879-3.999-6.316-13.892-24.146-44.358-86.808-27.288-147.014 10.061-35.48 34.638-64.025 73.051-84.844.314-.176.257-.144 6.491-3.555-46.873-17.735-100.133-20.301-149.371-12.379-44.559 7.128-67.8 18.369-89.625 32.793-106.114 70.047-136.442 165.033-116.884 244.762.002.008.005.015.007.023 22.925 93.362 118.772 151.82 122.841 154.263 2.285 1.372 4.908 2.14 7.847 2.14 4.203 0 30.593-.889 48.911-22.465 16.19-19.069 20.798-47.456 13.692-84.37-1.8-9.361-11.905-14.776-20.784-10.889-.248.108-25.139 10.836-55.493 9.697-36.467-1.373-65.137-18.932-85.323-52.222 5.206-9.051 16.122-25.83 32.467-40.094z" />
					<path d="m511.964 301.819c-13.194-85.969-50.834-156.468-117.108-195.337-34.955 19.127-22.897 12.534-25.507 13.958-31.085 16.826-50.79 39.267-58.568 66.699-13.892 48.995 12.199 102.537 24.268 123.587l16.832 26.476c50.15 4.573 102.187 1.811 146.146-17.012 5.229-2.24 8.95-3.546 11.766-7.959 1.974-3.097 2.743-6.79 2.171-10.412zm-111.689-78.66c-8.654 0-15.127-6.91-15.127-15 0-8.103 6.485-15 15.127-15 8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
				</g>
			</svg>
		);
};
