import React from "react";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Col,
	ListGroupItem,
	ListGroup,
	Row,
} from "reactstrap";
import { API } from "../../services/API";


export default function NoFishHelperCard({showModal}) {
	const [lureCount, setLureCount] = React.useState(0);
	const getCurrentTackleBox = () => {
		API.get("tackleBoxFiles/tackleBox.php?sort=1")
			.then((responseJson) => {
				responseJson = responseJson.data;
				console.log(responseJson);
				if (responseJson.status) {
					setLureCount(responseJson.data.length);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	React.useEffect(() => {
		getCurrentTackleBox();
	}, []);
		return (
			<Row className="justify-content-center mt-3">
				<Col lg="4">
					<Card>
						<CardHeader>
							<h3 className="h3 mb-0 text-center">No fish recorded yet!</h3>
						</CardHeader>
						<CardBody>
							<ListGroup className="list my--3" flush>
								<ListGroupItem className="px-0 text-center">
									<h4 className="h4">
										Let's get started by doing the following things...
									</h4>
								</ListGroupItem>
							</ListGroup>
							<ListGroup className="list my--3" flush>
								<ListGroupItem className="px-0 text-center">
									{lureCount < 1 && (<Button
										color="default"
										size="lg"
										type="button"
										href="#"
										onClick={(e) =>
											showModal(e, "showAddCustomLureModal")
										}
									>
										Add lure or bait to tackle box
									</Button>)}
									{lureCount > 0 && (
										<div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
											<i className="ni ni-check-bold"/>
										</div>
									)}
								</ListGroupItem>
							</ListGroup>
							<ListGroup className="list my--3" flush>
								<ListGroupItem className="px-0 text-center">
									<h5 className="h5">
										Uploading a photo on this step will allow us to figure out the location and weather...
									</h5>
									<Button
										color="primary"
										size="lg"
										type="button"
										href="#"
										onClick={(e) =>
											showModal(e, "showAddCatchModal")
										}
									>
										Add first fish!
									</Button>
								</ListGroupItem>
							</ListGroup>
							<ListGroup className="list my--3" flush>
								<ListGroupItem className="px-0 text-center">
									<h5 className="h5">
									We like to do the following when we get skunked and want to take notes on what didn't work...
									</h5>
									<Button
										color="default"
										size="lg"
										type="button"
										href="#"
										onClick={(e) =>
											showModal(e, "showAddReportModal")
										}
									>
										Add Trip Report!
									</Button>
								</ListGroupItem>
							</ListGroup>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
};
