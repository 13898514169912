/*!

=========================================================
* Argon Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFish,
  faEye,
  faNoteSticky,
  faDollarSign,
  faUser,
  faGear,
  faDollar,
} from "@fortawesome/free-solid-svg-icons";

function AdminNavbar({ theme, sidenavOpen, toggleSidenav, showModal }) {
  const [username, setUsername] = React.useState(
    localStorage.getItem("fishuname")
  );
  const [profileImage, setProfileImage] = React.useState(
    localStorage.getItem("fishuimg")
  );
  const profileImageObject =
    profileImage && profileImage !== "undefined"
      ? profileImage
      : require("assets/img/brand/biteLogrLogos/3dLogoOnly.jpg");
  window.addEventListener("storage", (e) => {
    setProfileImage(localStorage.getItem("fishuimg"));
    setUsername(localStorage.getItem("fishuname"));

    profileImageObject =
      profileImage && profileImage !== "undefined"
        ? profileImage
        : require("assets/img/brand/biteLogrLogos/3dLogoOnly.jpg");
  });
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            {/* <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": theme === "dark" },
                { "navbar-search-dark": theme === "light" }
              )}
            >
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative input-group-merge">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
              <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={closeSearch}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Form> */}

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              {/* <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem> */}
              <Button
                color="secondary"
                type="button"
                onClick={(e) => showModal(e, "showAddReportModal")}
              >
                <span className="btn-inner--icon mr-2">
                  <FontAwesomeIcon icon={faNoteSticky} />
                </span>
                <span className="btn-inner--text">Report</span>
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={(e) => showModal(e, "showAddCatchModal")}
              >
                <span className="btn-inner--icon mr-2">
                  <FontAwesomeIcon icon={faFish} />
                </span>
                <span className="btn-inner--text">Add Catch</span>
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={(e) => showModal(e, "showAddCustomLureModal")}
              >
                <span className="btn-inner--icon mr-2">
                  <FontAwesomeIcon icon={faEye} />
                </span>
                <span className="btn-inner--text">Add Lure</span>
              </Button>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span
                      className="avatar avatar-sm rounded-circle"
                      style={{ backgroundColor: "black" }}
                    >
                      <img alt="..." src={profileImageObject} />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {username}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem href="/admin/profile">
                    <FontAwesomeIcon icon={faUser} />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem href="/admin/setupplans">
                    <FontAwesomeIcon icon={faDollarSign} />
                    <span className="ml-1">Make money</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <FontAwesomeIcon icon={faGear} />
                    <span>Settings</span>
                  </DropdownItem>
                  {/* <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="../auth/logout">
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
