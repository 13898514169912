import React from "react";

export default function RodAndFishIcon({color}) {
		return (
			<svg
				id="Capa_1"
				enableBackground="new 0 0 512 512"
				height="512"
				viewBox="0 0 512 512"
				width="512"
				style={{
					fill: color,
				}}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					<path d="m377 362c-44.205 0-81.262 32.152-99.646 51.614-13.993-14.538-31.826-24.943-51.695-29.91l-5.021-1.255c-4.48-1.12-9.228-.114-12.869 2.729-3.641 2.842-5.769 7.203-5.769 11.822v80c0 4.619 2.128 8.98 5.769 11.823 2.664 2.08 5.92 3.177 9.231 3.177 1.214 0 2.436-.147 3.638-.448l5.021-1.255c19.869-4.967 37.703-15.373 51.695-29.91 18.384 19.461 55.441 51.613 99.646 51.613 62.038 0 110.01-63.333 112.022-66.029 3.971-5.321 3.971-12.62 0-17.941-2.012-2.697-49.984-66.03-112.022-66.03zm-145 94.424v-38.849c9.97 4.561 18.868 11.167 26.132 19.424-7.264 8.259-16.161 14.864-26.132 19.425zm64.559-19.497c16.055-17.702 47.726-44.927 80.441-44.927 8.672 0 17.129 1.83 25.16 4.811-6.623 12.285-10.16 26.073-10.16 40.189 0 14.079 3.52 27.83 10.109 40.09-8.128 3.049-16.581 4.91-25.109 4.91-34.996 0-66.521-29.746-80.441-45.073zm131.926 25.866c-4.226-7.902-6.485-16.746-6.485-25.793 0-9.074 2.271-17.943 6.52-25.861 12.434 8.837 22.534 18.902 28.921 25.934-7.051 7.774-17.115 17.384-28.956 25.72z" />
					<path d="m500.337 275.905c-12.465-14.698-19.33-35.669-19.33-59.051v-201.854h-.028c0-3.839-1.464-7.678-4.393-10.607-5.858-5.858-15.355-5.858-21.213 0l-240.937 240.937c-20.832-12.843-47.381-12.843-68.213 0l-25.998-25.998c-5.858-5.858-15.355-5.858-21.213 0l-42.426 42.426c-5.858 5.858-5.858 15.355 0 21.213s15.355 5.858 21.213 0l31.82-31.82 15.391 15.391c-6.656 10.797-9.863 23.125-9.619 35.405l-101.232 101.233c-17.545 17.545-17.545 46.094 0 63.64 17.545 17.545 46.094 17.545 63.64 0l101.231-101.232c17.075.338 34.259-5.975 47.261-18.977 13.676-13.676 19.957-31.979 18.874-49.916 23.254 4.044 48.059-2.874 65.979-20.795 17.33-17.33 24.386-41.099 21.176-63.677 22.577 3.21 46.347-3.847 63.677-21.176 25.611-25.611 28.789-65.288 9.539-94.392l45.47-45.47v165.668c0 30.48 9.394 58.343 26.45 78.455 2.938 3.464 4.108 7.916 3.298 12.535-1.03 5.868-6.037 10.874-11.904 11.904-4.589.805-9.023-.349-12.479-3.25-3.41-2.863-5.365-7.054-5.365-11.498 0-8.284-6.716-15-15-15s-15 6.716-15 15c0 13.33 5.858 25.894 16.073 34.472 8.166 6.857 18.417 10.521 28.987 10.521 2.646 0 5.313-.229 7.971-.696 18.164-3.188 33.077-18.102 36.266-36.266 2.345-13.361-1.288-26.892-9.966-37.125zm-443.751 169.702c-5.849 5.849-15.365 5.849-21.213 0-5.848-5.848-5.848-15.365 0-21.213l89.637-89.637c2.599 4.216 5.707 8.205 9.358 11.856s7.64 6.758 11.856 9.358zm148.492-120.209c-13.646 13.646-35.851 13.646-49.497 0-9.852-9.852-12.588-24.165-8.214-36.498l22.356 22.356c5.858 5.858 15.355 5.858 21.213 0s5.858-15.355 0-21.213l-22.356-22.356c12.333-4.374 26.646-1.639 36.498 8.214 13.646 13.646 13.646 35.851 0 49.497zm84.853-70.71c-13.827 13.827-34.485 16.749-51.209 8.782l59.991-59.991c7.966 16.724 5.045 37.382-8.782 51.209zm84.853-84.853c-13.827 13.827-34.485 16.749-51.208 8.782l59.991-59.991c7.965 16.724 5.044 37.382-8.783 51.209z" />
				</g>
			</svg>
		);
}
