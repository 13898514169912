/*!

=========================================================
* Argon Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

function AuthNavbar() {
  const [navOpen, setNavOpen] = React.useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          {/* <NavbarBrand to="/" tag={Link}>
            BITE LOGR
          </NavbarBrand> */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => toggleNav()}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Collapse isOpen={navOpen} className="navbar-custom-collapse" navbar>
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/auth/home">
                    <img
                      alt="..."
                      src={require("assets/img/brand/biteLogrLogos/LogoHorizontal.png")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => toggleNav()}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink to="/auth/home" tag={Link} onClick={() => toggleNav()}>
                  <span className="nav-link-inner--text">Home</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/auth/register"
                  tag={Link}
                  onClick={() => toggleNav()}
                >
                  <span className="nav-link-inner--text">Register</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/auth/login"
                  tag={Link}
                  onClick={() => toggleNav()}
                >
                  <span className="nav-link-inner--text">Login</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/faq" tag={Link} onClick={() => toggleNav()}>
                  <span className="nav-link-inner--text">FAQ</span>
                </NavLink>
              </NavItem>
            </Nav>
            <hr className="d-lg-none" />
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AuthNavbar;
