import React from "react";
import { Button, FormGroup, Input, Label, Form, Col } from "reactstrap";
import Select2 from "react-select2-wrapper";
import { weatherConditions } from "variables/mapOptions.js";

export default function WeatherStep({nextStep, previousStep, onSave}) {
	const [airTemp, setAirTemp] = React.useState('');
	const [moonPhase, setMoonPhase] = React.useState('');
	const [windSpeed, setWindSpeed] = React.useState('');
	const [windDirection, setWindDirection] = React.useState('');
	const [weatherCondition, setWeatherCondition] = React.useState('');
	const [pressure, setPressure] = React.useState('');
	const [waveHeight, setWaveHeight] = React.useState('');

	const saveStats = () => {
		onSave([
			{
				key: "moonPhase",
				val: moonPhase,
			},
			{
				key: "airTemp",
				val: airTemp,
			},
			{
				key: "windSpeed",
				val: windSpeed,
			},
			{
				key: "windDirection",
				val: windDirection,
			},
			{
				key: "weatherCondition",
				val: weatherCondition,
			},
			{
				key: "pressure",
				val: pressure,
			},
			{
				key: "waveHeight",
				val: waveHeight,
			},
		]);
		nextStep();
	};

		return (
			<>
				<Form>
					<FormGroup className="row">
						<Label className="form-control-label" htmlFor="airTemp" md="2">
							Air Temp
						</Label>
						<Col md="10">
							<Input
								placeholder="degrees farneheit"
								id="airTemp"
								type="text"
								onChange={(e) => {
									setAirTemp(e.target.value);
								}}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="form-control-label" htmlFor="moonPhase" md="2">
							Moon Phase
						</Label>
						<Col md="10">
							<Select2
								value={moonPhase}
								className="form-control"
								data-minimum-results-for-search="Infinity"
								id="moonPhase"
								options={{
									placeholder: "Select...",
								}}
								onSelect={(e) => {
									setMoonPhase(e.target.value);
								}}
								data={[
									{ id: "New Moon", text: "New Moon" },
									{ id: "Waxing Crescent", text: "Waxing Crescent" },
									{ id: "First Quarter", text: "First Quarter" },
									{ id: "Waxing Gibbous", text: "Waxing Gibbous" },
									{ id: "Full Moon", text: "Full Moon" },
									{ id: "Waning Gibbous", text: "Waning Gibbous" },
									{ id: "Last Quarter", text: "Last Quarter" },
									{ id: "Waning Crescent", text: "Waning Crescent" },
								]}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="form-control-label" htmlFor="windSpeed" md="2">
							Wind Speed
						</Label>
						<Col md="10">
							<Input
								placeholder="mph"
								id="windSpeed"
								type="text"
								onChange={(e) => {
									setWindSpeed(e.target.value);
								}}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="form-control-label"
							htmlFor="windDirection"
							md="2"
						>
							Wind Direction
						</Label>
						<Col md="10">
							<Select2
								value={windDirection}
								className="form-control"
								data-minimum-results-for-search="Infinity"
								id="windDirection"
								options={{
									placeholder: "N E S W",
								}}
								onSelect={(e) => {
									setWindDirection(e.target.value);
								}}
								data={[
									{ id: "N", text: "N" },
									{ id: "NE", text: "NE" },
									{ id: "E", text: "E" },
									{ id: "SE", text: "SE" },
									{ id: "S", text: "S" },
									{ id: "SW", text: "SW" },
									{ id: "W", text: "W" },
									{ id: "NW", text: "NW" },
								]}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="form-control-label"
							htmlFor="weatherCondition"
							md="2"
						>
							Weather Condition
						</Label>
						<Col md="10">
							<Select2
								value={weatherCondition}
								className="form-control"
								id="weatherCondition"
								options={{
									placeholder: "Select...",
								}}
								onSelect={(e) => {
									setWeatherCondition(e.target.value);
								}}
								data={weatherConditions}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="form-control-label" htmlFor="waveHeight" md="2">
							Wave Height
						</Label>
						<Col md="10">
							<Input
								placeholder="feet"
								id="waveHeight"
								type="text"
								onChange={(e) => {
									setWaveHeight(e.target.value);
								}}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label className="form-control-label" htmlFor="pressure" md="2">
							Barometric Pressure
						</Label>
						<Col md="10">
							<Input
								placeholder="millibars"
								id="pressure"
								type="text"
								onChange={(e) => {
									setPressure(e.target.value);
								}}
							/>
						</Col>
					</FormGroup>
				</Form>
				<div className="modal-footer p-0">
					<Button
						color="secondary"
						type="button"
						onClick={() => previousStep()}
					>
						Back
					</Button>
					<Button
						color="primary"
						type="button"
						onClick={() => saveStats()}
					>
						Next
					</Button>
				</div>
			</>
		);
	}