import React from "react";
import { Button } from "reactstrap";
const mapStyles = [
	{
		featureType: "administrative",
		elementType: "labels.text.fill",
		stylers: [{ color: "#444444" }],
	},
	{
		featureType: "landscape",
		elementType: "all",
		stylers: [{ color: "#f2f2f2" }],
	},
	{
		featureType: "poi",
		elementType: "all",
		stylers: [{ visibility: "off" }],
	},
	{
		featureType: "road",
		elementType: "all",
		stylers: [{ saturation: -100 }, { lightness: 45 }],
	},
	{
		featureType: "road.highway",
		elementType: "all",
		stylers: [{ visibility: "simplified" }],
	},
	{
		featureType: "road.arterial",
		elementType: "labels.icon",
		stylers: [{ visibility: "off" }],
	},
	{
		featureType: "transit",
		elementType: "all",
		stylers: [{ visibility: "off" }],
	},
	{
		featureType: "water",
		elementType: "all",
		stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
	},
];

//Lehmann Systems project BiteLogr
const mapApiKey = "AIzaSyCAT1bmlY4jyTJx2OxehjwisTMRHvuSyJU";

const weatherConditions = [
	{ id: "Clear/Sunny", text: "Clear/Sunny", bgClass: "bg-gradient-yellow" },
	{
		id: "Partly Cloudy",
		text: "Partly Cloudy",
		bgClass: "bg-gradient-teal",
	},
	{ id: "Cloudy", text: "Cloudy", bgClass: "bg-gradient-gray" },
	{ id: "Mist", text: "Mist", bgClass: "bg-gradient-gray" },
	{ id: "Light Rain", text: "Light Rain", bgClass: "bg-gradient-gray-dark" },
	{
		id: "Moderate Rain",
		text: "Moderate Rain",
		bgClass: "bg-gradient-gray-dark",
	},
	{ id: "Heavy Rain", text: "Heavy Rain", bgClass: "bg-gradient-gray-dark" },
	{ id: "Light Snow", text: "Light Snow", bgClass: "bg-gradient-indigo" },
	{ id: "Moderate Snow", text: "Moderate Snow", bgClass: "bg-gradient-indigo" },
	{ id: "Heavy Snow", text: "Heavy Snow", bgClass: "bg-gradient-indigo" },
	{ id: "Ice Pellets", text: "Ice Pellets", bgClass: "bg-gradient-indigo" },
];

// InfoWindow component
const InfoWindow = ({
	setLocationFromMap,
	hideMarkerAndInfoWindow,
	textForInfoWindow,
}) => {
	const infoWindowStyle = {
		position: "relative",
		bottom: 150,
		left: "-45px",
		width: 220,
		backgroundColor: "white",
		boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
		padding: 10,
		fontSize: 14,
		zIndex: 100,
		textAlign: "center",
	};

	return (
		<div style={infoWindowStyle}>
			<h4>{textForInfoWindow}</h4>
			<div>
				<Button
					color="secondary"
					size="md"
					type="button"
					onClick={(e) => {
						setLocationFromMap(e);
					}}
				>
					Yes
				</Button>
				<Button
					color="secondary"
					size="md"
					type="button"
					onClick={(e) => {
						hideMarkerAndInfoWindow(e);
					}}
				>
					No
				</Button>
			</div>
		</div>
	);
};

// Marker component
const Marker = ({
	show,
	textForInfoWindow,
	hideMarkerAndInfoWindow,
	setLocationFromMap,
}) => {
	const markerStyle = {
		border: "1px solid white",
		borderRadius: "50%",
		height: 10,
		width: 10,
		backgroundColor: "#FB6340",
		cursor: "pointer",
		zIndex: 10,
	};

	return (
		<>
			{show && (
				<>
					<div style={markerStyle} />
					<InfoWindow
						setLocationFromMap={(e) => {
							setLocationFromMap(e);
						}}
						hideMarkerAndInfoWindow={(e) => {
							hideMarkerAndInfoWindow(e);
						}}
						textForInfoWindow={textForInfoWindow}
					/>
				</>
			)}
		</>
	);
};

let getCityStateFromGeoResults = (results) => {
	if (results.length > 0) {
		for (var r = 0, rl = results.length; r < rl; r += 1) {
			var result = results[r];
			if (
				//result.types[0] === "neighborhood" ||
				result.types[0] === "administrative_area_level_3" ||
				result.types[0] === "administrative_area_level_2" ||
				result.types[0] === "administrative_area_level_1" ||
				result.types[0] === "country" ||
				result.types[0] === "postal_code"
			) {
				return result.formatted_address;
			}
		}
	}
	return "Unknown Address";
};

export {
	mapStyles,
	mapApiKey,
	weatherConditions,
	InfoWindow,
	Marker,
	getCityStateFromGeoResults,
};
